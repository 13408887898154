export const getBaseApiUrl = () => {
  // return "http://localhost:8080";
  // return "https://asa-mco-dev-001-nemcis.private.azuremicroservices.io";
  return "https://sys-nemcis-dhhs.nebraska.gov";
}


export const downloadFileToken = () => {
  return '?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2026-02-19T00:48:52Z&st=2024-12-10T16:48:52Z&spr=https&sig=DIaLngeaQzCtz2jbCkDwY1pNstdD3J3m0GW1NPM4K74%3D';
}


export const getB2CRedirectSignInUrl = () => {

  const b2c_client_id = process.env.REACT_APP_B2C_CLIENT_ID;
  const b2c_tenant = process.env.REACT_APP_B2C_TENANT_ID;
  const b2c_policy_name = process.env.REACT_APP_B2C_POLICY_NAME;
  const b2c_redirect_uri = process.env.REACT_APP_B2C_REDIRECT_URI;
  const b2c_client_secret = process.env.REACT_APP_B2C_CLIENT_SECRET;

  const signInUrl = `https://${b2c_tenant}.b2clogin.com/${b2c_tenant}.onmicrosoft.com/oauth2/v2.0/authorize?p=${b2c_policy_name}&client_id=${b2c_client_id}&nonce=defaultNonce&redirect_uri=${b2c_redirect_uri}&scope=openid ${b2c_client_id}&response_type=code&isMFA=true`;
  return signInUrl;  
}

export const getB2CRedirectLogoutUrl = () => {
  
  const b2c_client_id = process.env.REACT_APP_B2C_CLIENT_ID;
  const b2c_tenant = process.env.REACT_APP_B2C_TENANT_ID;
  const b2c_policy_name = process.env.REACT_APP_B2C_POLICY_NAME;
  const b2c_redirect_uri = process.env.REACT_APP_B2C_REDIRECT_URI;
  const b2c_client_secret = process.env.REACT_APP_B2C_CLIENT_SECRET;

  const logoutUrl = `https://${b2c_tenant}.b2clogin.com/${b2c_tenant}.onmicrosoft.com/oauth2/v2.0/logout?p=${b2c_policy_name}&client_id=${b2c_client_id}&post_logout_redirect_uri=${b2c_redirect_uri}`;
  return logoutUrl;  
}