import axios from "../utils/axiosConfig";


const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
};
export const useReportInventoryService = () => {

    const GetReportInveroryService = {
        getReportInventory: () => {
            let url = "/api/reportinventory/getAll";
            return axios.get(`${url}`,{headers});
        },

        getMasterLookupDate: () => {
            let url = "/api/masterLookUpTable/getAll";
            return axios.get(`${url}`,{headers});
        },

        getMasterLookupValuesDate: () => {
            let url = "/api/masterLookUpTable/getLookupValues";
            return axios.get(`${url}`,{headers});
        },

        saveMctreportinstance: (data: any) => {
            let url = "/api/mctreportinstance/create";
            return axios.post(`${url}`, data);
        },

        getMctreportinstanceDetailsById: (id: any) => {
            let url = "/api/mctreportinstance/get/" + id;
            return axios.get(`${url}`);
        },

        userLoginService: (data: any) => {
            let url = "/api/user/login";
            return axios.post(`${url}`, data);
        },

        getMcProgramReport: () => {
            let url = "/api/mcprogramreport/getAll";
            return axios.get(`${url}`);
        },

        getCatalogDetailsLIst: (programReportSid: any) => {
            let url = "/api/mcprogramreport/getAllCatalogDetails/" + programReportSid;
            return axios.get(`${url}`);
        },

        getMcUserGroups: () => {
            let url = "/api/userGroup/getAll";
            return axios.get(`${url}`,{headers});
        },

        getMcOrganizationUnits: () => {
            let url = "/api/orgUnits/getAll";
            return axios.get(`${url}`);
        },
        getCheckListItem: (checklistId: any) => {
            let url = "/api/mcprogramreport/getCheckListItemByCheckListId/" + checklistId;
            return axios.get(`${url}`);
        },

        getCheckListItemsByProgramReportSid: (programReportSid: any, checklistTypeLkpcd: any) => {
            let url = "/api/mcprogramreport/getCheckListItemByProgramReportSId/" + programReportSid + "/" + checklistTypeLkpcd;
            return axios.get(`${url}`);
        },

        getCheckListItemByCheckListTypeId: (checklistId: any, checklistTypeLkpcd: any) => {
            let url = "/api/mcprogramreport/getCheckListItemByCheckListTypeId/" + checklistId + "/" + checklistTypeLkpcd;
            return axios.get(`${url}`);
        },


        getProgramReportByProgramReportSid: (programReportSid: any) => {
            let url = "/api/mcprogramreport/getProgramReportByProgramReportSid/" + programReportSid;
            return axios.get(`${url}`);
        },

        getReportActivityList: (mctReportInstanceSid: any) => {
            let url = "/api/mctreportactivity/get/" + mctReportInstanceSid;
            return axios.get(`${url}`);
        },

        getAllMcReports: () => {
            let url = "/api/mcreport/getAll";
            return axios.get(`${url}`);
        },

        getAllMcUsers: () => {
            let url = "/api/user/getAll";
            return axios.get(`${url}`);
        },

        getUserXUserGroupList: (userSid: any) => {
            let url = "/api/userxusergroup/" + userSid;
            return axios.get(`${url}`);
        },

        getAllGroupByUserName: (userName: any) => {
            let url = "/api/userxusergroup/getAllGroupByUserSid/" + userName;
            return axios.get(`${url}`);
        },

        getMcUserGroupsByGroupCode: (groupCode: any) => {
            let url = "/api/userxusergroup/getMcUserGroupsByGroupCode/" + groupCode;
            return axios.get(`${url}`);
        },

        getReviewAssignmentList: (userSid: any) => {
            let url = "/api/userReviewAssignment/" + userSid;
            return axios.get(`${url}`);
        },

        getReviewAssignmentListByUnitCode: (orgUnitCode: any) => {
            let url = "/api/userReviewAssignment/unitCode/" + orgUnitCode;
            return axios.get(`${url}`);
        },

        uploadFile: (formData: FormData) => {
            let url = "/api/mcfile/upload";
            return axios.post(url, formData, {
                headers: {
                    // Do not set the Content-Type header manually
                    'Content-Type': 'multipart/form-data'
                }
            });
        },

        mctReportAttachments: (mctReportInstanceSid: any) => {
            let url = "/api/mctReportAttachments/" + mctReportInstanceSid;
            return axios.get(`${url}`);
        },

        getUploadedFilesListBySid: (containerName: any, subContainerName: any) => {
            let url = `/api/mcfile/getall/${containerName}/${subContainerName}`;
            return axios.get(`${url}`);
        },

        deleteUploadedFile: (data: any) => {
            let url = "/api/mcfile/delete";
            return axios.delete(`${url}`, { data });
        },

        updatedUploadedFileStatus: (data: any) => {
            let url = "/api/mctReportAttachments/updateattachmentstatus";
            return axios.post(`${url}`, data);
        },

        uploadFileValidate: (formData: FormData) => {
            let url = "/api/excel/validate";
            return axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },

        saveMcoActions: (formData: FormData) => {
            let url = "/api/mcoactions/save";
            return axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },

        submitMcoActions: (formData: FormData) => {
            let url = "/api/mcoactions/submit";
            return axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },

        getLatestActivityByInstanceId: (mctReportInstanceSid: any) => {
            let url = "/api/mctreportactivity/getlastactivity/" + mctReportInstanceSid;
            return axios.get(`${url}`);
        },

        getLAttachmentByActivityId: (mctReportInstanceSid: any) => {
            let url = "/api/mctReportAttachments/get/" + mctReportInstanceSid;
            return axios.get(`${url}`);
        },

        getUploadedFileByAttchanchmentId: (containerName: any, subContainerName: any, attachmentId: any) => {
            let url = `/api/mcfile/getFileByAttachmentId/${containerName}/${subContainerName}/${attachmentId}`;
            return axios.get(`${url}`);
        },

        downloadFileContentAttchanchmentId: (containerName: any, subContainerName: any, attachmentId: any) => {
            let url = `/api/mcfile/downloadFileByAttachmentId/${containerName}/${subContainerName}/${attachmentId}`;
            return axios.get(`${url}`, { responseType: 'blob' });
        },

        downloadTemplateFileByFileName: (containerName: any, fileName: any) => {
            let url = `/api/mcfile/downloadFileByFileName/${containerName}/${fileName}`;
            return axios.get(`${url}`, { responseType: 'blob' });
        },


        deleteActivityAndAttchament: (activityId: any) => {
            let url = "/api/mctreportactivity/deleteactivity/" + activityId;
            return axios.delete(`${url}`);
        },

        updateactivitychecklistattachments: (mctReportActivitySid: any, updatedBy: any, updatedDate: any) => {
            let url = "/api/mctreportactivity/updateactivitychecklistattachments";
            return axios.post(`${url}`, { mctReportActivitySid, updatedBy, updatedDate });
        },

        getReportChecklistByActivityId: (activityId: any) => {
            let url = "/api/mctreportchecklistrspns/getall/" + activityId;
            return axios.get(`${url}`);
        },

        updateActivity: (data: any) => {
            let url = "/api/mctreportactivity/updateactivity";
            return axios.post(`${url}`, data);
        },

        getReportReviewerGroupListByInstanceId: (programReportSid: any, actionLkpcd: any) => {
            let url = "/api/reportReviewerGroup/getAll/" + programReportSid + "/" + actionLkpcd;
            return axios.get(`${url}`);
        },

        getAllReportReviewer: () => {
            let url = "/api/reportReviewerGroup/getAllReportReviewer";
            return axios.get(`${url}`);
        },

        getMCTReportSubActivityByActivityId: (activityId: any) => {
            let url = "/api/mctreportsubactivity/getByActivityId/" + activityId;
            return axios.get(`${url}`);
        },

        getMCTReportSubActivityBySubActivityId: (subActivityId: any) => {
            let url = "/api/mctreportsubactivity/getBySubActivityId/" + subActivityId;
            return axios.get(`${url}`);
        },

        getAttachemntBySubActivityId: (subActivityId: any) => {
            let url = "/api/mctReportAttachments/getAttachmentBySubActivityId/" + subActivityId;
            return axios.get(`${url}`);
        },

        getAllChecklistBySubActivityId: (subActivityId: any) => {
            let url = "/api/mctreportchecklistrspns/getallchecklistsubactivity/" + subActivityId;
            return axios.get(`${url}`);
        },

        getAttachmentByActivityId: (mctReportInstanceSid: any) => {
            let url = "/api/mctReportAttachments/getAttachmentByActivityId/" + mctReportInstanceSid;
            return axios.get(`${url}`);
        },

        saveStateReviewWithoutFile: (data: any) => {
            let url = "/api/stateReviewerActions/saveWithoutFile";
            return axios.post(`${url}`, data);
        },

        submitStateReviewWithoutFile: (data: any) => {
            let url = "/api/stateReviewerActions/submitWithoutFile";
            return axios.post(`${url}`, data);
        },

        saveStateReviewWithFile: (formData: FormData) => {
            let url = "/api/stateReviewerActions/saveWithFile";
            return axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },

        submitStateReviewWithFile: (formData: FormData) => {
            let url = "/api/stateReviewerActions/submitWithFile";
            return axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },

        getAllSubActivitiesByInstanceId: (mctReportInstanceSid: any) => {
            let url = "/api/mctreportsubactivity/getAllSubActivities/" + mctReportInstanceSid;
            return axios.get(`${url}`);
        },

        getAllUserGroupsFromDBByUserGroupId: (usergroupcode: any) => {
            let url = "/api/mcusergroupgroupxpage/" + usergroupcode;
            return axios.get(`${url}`);
        },

        getAllInstaceRecordsForUpload: (planId: any) => {
            let url = "/api/mctreportinstance/getForUpload/"+planId;
            return axios.get(`${url}`);
        },

        saveloadReport: (formData: FormData) => {
            let url = "/api/mctreportinstance/saveuploadreport";
            return axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },

        submitUploadReport: (formData: FormData) => {
            let url = "/api/mctreportinstance/submituploadreport";
            return axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        },

        getAllMctfinalmessage: (userId:any) => {
            let url = "/api/mctfinalmessage/getAll/"+userId;
            return axios.get(`${url}`);
        },

        getAllWithUserGroupCode: (data:any) => {
            let url = "/api/mctfinalmessage/getAllWithUserGroupCode";
            return axios.post(`${url}`, data);
        },

        getAllMcAlertsDetails: () => {
            let url = "/api/mcalert/getAll";
            return axios.get(`${url}`);
        },

        getB2cAuthCode:  (authCode: any) => {
            let url = "/api/b2c" ;
            // return axios.get(`${url}`,{ params: { authCode: authCode } });
            return axios.post(`${url}`,{},{
                headers: {
                    "Authorization-Code": `Bearer ${authCode}`
                }
            });
        },

        checkReviewGroupByUserId: (userSid: any, orgUnitCode: any,planId:any) => {
            let url = `/api/userReviewAssignment/user/${userSid}/unit/${orgUnitCode}/plan/${planId}`;
            return axios.get(`${url}`);
        }
    }
    return GetReportInveroryService;

}