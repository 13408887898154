import axios from "axios";
import { msalInstance } from "../.";
import { getBaseApiUrl } from "./url-constant";

axios.defaults.baseURL = getBaseApiUrl();

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "*/*";

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

const onRefreshed = (token: string) => {
  refreshSubscribers.map((callback) => callback(token));
};

const addRefreshSubscriber = (callback: (token: string) => void) => {
  refreshSubscribers.push(callback);
};

axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('msalToken') || sessionStorage.getItem('userToken');
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    } else {
      delete config.headers["Authorization"];
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);


axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (sessionStorage.getItem('msalToken') && sessionStorage.getItem('signedInUserType') !== 'HealthPlanUser') {
      // Handle MSAL token refresh
      if (error.response && error.response.status === 401 && sessionStorage.getItem('msalToken') && (!originalRequest._retry || originalRequest._retryCount < 2)) {
        originalRequest._retry = true;
        originalRequest._retryCount = originalRequest._retryCount || 0;
        originalRequest._retryCount++;

        const msalToken = sessionStorage.getItem('msalToken');
        if (msalToken) {
          console.log('---- in site new token code----');
          // Try to acquire a new token silently
          const account = msalInstance.getAllAccounts()[0];
          const response = await msalInstance.acquireTokenSilent({
            account,
            scopes: ['User.Read', 'Group.Read.All'],
          });

          try {
            if (response) {
              // Update the Authorization header with the new token
              const accessToken = response.accessToken;
              sessionStorage.setItem('msalToken', accessToken);
              originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;
            }

            // Retry the request with the new token
            return axios(originalRequest);
          } catch (refreshError) {
            console.error('Error refreshing token:', refreshError);
            throw refreshError;
          }
        }
      }
    } else if (sessionStorage.getItem('msalToken') && sessionStorage.getItem('signedInUserType') === 'HealthPlanUser') {
      // Handle B2C token refresh
      if (error.response && error.response.status === 401 && sessionStorage.getItem('msalToken')) {
        if (!isRefreshing) {
          isRefreshing = true;

          const b2cToken = sessionStorage.getItem('msalToken');
          if (b2cToken) {
            console.log('---- in B2C token refresh code----');
            try {
              const response = await axios.post('/api/user/b2c/refresh-token', {});

              if (response.status === 200) {
                const newUserToken = response.data.access_token;
                sessionStorage.setItem('msalToken', newUserToken);
                originalRequest.headers["Authorization"] = `Bearer ${newUserToken}`;
                isRefreshing = false;
                onRefreshed(newUserToken);
                refreshSubscribers = [];
                // Retry the request with the new token
                return axios(originalRequest);
              } else {
                // Handle refresh token failure
                console.error('Failed to refresh userToken:', response);
                throw new Error('Failed to refresh userToken');
              }
            } catch (refreshError) {
              console.error('Error refreshing b2c token:', refreshError);
              isRefreshing = false;
              throw refreshError;
            }
          }
        } else {
          return new Promise((resolve) => {
            addRefreshSubscriber((token) => {
              originalRequest.headers["Authorization"] = `Bearer ${token}`;
              resolve(axios(originalRequest));
            });
          });
        }
      }
    }
    else {
      // Handle userToken refresh
      if (error.response && error.response.status === 401 && sessionStorage.getItem('userToken')) {
        if (!isRefreshing) {
          isRefreshing = true;
          const refreshToken = sessionStorage.getItem('userToken');
          if (refreshToken) {
            try {
              // Make a request to your authentication server to get a new userToken
              const response = await axios.post('/api/user/refresh-token', { refreshToken });

              if (response.status === 200) {
                const newUserToken = response.data.token;
                sessionStorage.setItem('userToken', newUserToken);
                originalRequest.headers["Authorization"] = `Bearer ${newUserToken}`;
                isRefreshing = false;
                onRefreshed(newUserToken);
                refreshSubscribers = [];
                // Retry the request with the new token
                return axios(originalRequest);
              } else {
                // Handle refresh token failure
                console.error('Failed to refresh userToken:', response);
                throw new Error('Failed to refresh userToken');
              }
            } catch (refreshError) {
              console.error('Error refreshing userToken:', refreshError);
              isRefreshing = false;
              throw refreshError;
            }
          }
        } else {
          return new Promise((resolve) => {
            addRefreshSubscriber((token) => {
              originalRequest.headers["Authorization"] = `Bearer ${token}`;
              resolve(axios(originalRequest));
            });
          });
        }
      }

      return Promise.reject(error);
    }
  }
);

export default axios;