import React, { useRef, useState } from "react";
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import { Link, Route } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ManageDevIcon from '../../img/manage_dev.png'
import arrow from '../../img/arrow.png'
import { getB2CRedirectSignInUrl } from "../../utils/url-constant";
import BackButton from '../../component/Common/BackBtn'

export default function B2CUserSignIn() {

  const b2cSignInRouteFn = async () => {
    window.location.href = getB2CRedirectSignInUrl();
  }

  return (
    <div className="flex flex-col overflow-x-hidden h-screen ">
        <React.Fragment>
          <Navbar />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ alignSelf: 'flex-start' }}>
          <BackButton />
        </div>
      </div>
          <div className="flex-grow">
            <div className="home_page_css">
              <div style={{ width: '100%' }} className={'row'}>
                <div className="col-12">
                  <h2 className="landing-heading-title">
                    Nebraska Managed Care Insight System
                  </h2>
                  <p className="font-base text-[16px] font-[#0F)F10] my-0">Please sign in to access the application.</p>
                </div>
              </div>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  '& > :not(style)': {
                    m: 1,
                    width: { xs: '100%', sm: 260 },
                    height: { xs: 'auto', sm: 175 },
                    maxWidth: 260,
                    maxHeight: 175,
                  },
                  my: 1,
                  m: 0,
                }}
              >
                {/* {B2C User Sign In} */}
                <Route>
                  <Link
                    style={{
                      textDecoration: 'none',
                      marginTop: 20,
                      marginBottom: 50,
                    }}
                    to={'/'}
                  >
                    <Paper
                      sx={{ border: 7, borderColor: '#bac6de', borderRadius: '26px' }}
                      elevation={3}
                      onClick={b2cSignInRouteFn}
                    >
                      <Typography component="div">
                        <Box sx={{ fontWeight: 'bold', m: 1 }}>
                          B2C Sign In
                        </Box>
                      </Typography>
                      <Box>
                        <Box
                          sx={{
                            position: 'relative',
                            left: '0%',
                          }}
                        >
                          <Avatar
                            alt="Mange Developers Icon"
                            sx={{ width: 60, height: 60 }}
                            src={ManageDevIcon}
                          />
                          <Box
                            sx={{
                              inlineSize: 130,
                              fontSize: 10,
                              letterSpacing: 0,
                              position: 'relative',
                              top: -60,
                              left: '35%',
                              height: '9vh',
                            }}
                          >
                            Click here to access NEMCIS
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            maxWidth: '50px',
                            position: 'relative',
                            left: '75%',
                            top: -25,
                          }}
                        >
                          <Avatar
                            alt="Arrow"
                            sx={{ width: 30, height: 25 }}
                            src={arrow}
                          />
                        </Box>
                      </Box>
                    </Paper>
                  </Link>
                </Route>
              </Box>
            </div>
          </div>
          <Footer />
        </React.Fragment>
    </div >
  );
};