import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import FormInput from '../../component/Common/FormInput'
import Button from '../../component/Common/Button'
import { useHistory } from 'react-router-dom'
import BackButton from '../../component/Common/BackBtn'
import { Typography } from '@mui/material'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import PageSubTitle from '../../component/Common/PageSubTitle'
import './user-sign-in.css'
import { useReportInventoryService } from '../../services/ReportInventoryServices';
import { toast } from 'react-toastify';
import { Success_Messages, Warning_Messages, Error_Messages } from '../../utils/statusConstantValues';

export default function AppSignInPage(props: any) {
  interface Credentials {
    username: string;
    password: string;
    usertype: string;
  }
  const history = useHistory()
  const [logindetails, setLogindetails] = useState<Credentials>({
    username: '',
    password: '',
    usertype: 'HealthPlanUser',
  })
  const [loadingButton, setLoadingButton] = useState(false);
  const [isShowPassword, setShowPassword] = useState(false)
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const ReportInventoryService = useReportInventoryService();

  const userLoginApi = async () => {
    setLoadingButton(true)
    ReportInventoryService.userLoginService(logindetails).then((response) => {
      if (response.status === 200) {
        sessionStorage.setItem('userToken', response.data.token)
        sessionStorage.setItem('signedInUserType', 'HealthPlanUser')
        sessionStorage.setItem('signedInUserId', response.data.username)
        setTimeout(() => {
          setLoadingButton(false);
          history.push('/admin-dashboard');
        }, 2000);
      }
    }).catch((e) => {
      setLoadingButton(false);
      if (e.response.status === 404) {
        toast.error(Error_Messages.Failed_to_login);
        console.error('Failed to login');
        setLoadingButton(false);
      }
      else {
        console.error('Login error:', e);
      }
    })
  }

  const signInHandle = () => {
    userLoginApi().catch((e) => {
      console.error('Unhandled error:', e);
    });
  };

  const showPassword = () => {
    setShowPassword(!isShowPassword)
  }

  function validateForm() {
    if (logindetails.username && logindetails.password) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }

  useEffect(() => {
    validateForm()
  }, [logindetails])

  function handleInputChange(event: any) {
    const { name, value } = event.target;
    setLogindetails((prevState) => ({ ...prevState, [name]: value }));
  }

  const userTypeDropdownChoices = [
    {
      label: "Health Plan",
      value: "HealthPlanUser",
    }
  ];

  return (
    <>
      <Navbar />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ alignSelf: 'flex-start' }}>
          <BackButton />
        </div>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <PageSubTitle text="Sign In" />
        </div>
      </div>
      <div className="flex-grow">
        <div>
          <Box sx={{ m: 20, maxWidth: '550px', margin: '30px auto' }}>
            <Box sx={{ m: 6 }}>
              <InputText
                label="User Name"
                name="username"
                placeholder="User Name"
                value={logindetails?.username}
                onChange={handleInputChange}
                mandatoryLabel={true}
              // small={true}
              />
              <InputText
                label="Password"
                name="password"
                placeholder="Password"
                type={isShowPassword ? 'text' : 'password'}
                value={logindetails?.password}
                onChange={handleInputChange}
                mandatoryLabel={true}
              />

              <Box>
                <a href="#" onClick={showPassword}>
                  Show Password
                </a>
              </Box>

              <Dropdown
                choices={userTypeDropdownChoices}
                emptyChoiceLabel="Select User Type"
                label="User Type"
                name="userType"
                data-testid="user_type_testid"
                value={logindetails?.usertype}
                onChange={handleInputChange}
                disabled={true}
              />
              <Button
                disabled={!isFormValid}
                loading={loadingButton}
                className="OkButton"
                onClick={signInHandle}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </div>
      </div>
      <Footer />
    </>
  )
}

