import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory } from 'react-router-dom'
import './reminders-home.css'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import PageSubTitle from '../../component/Common/PageSubTitle'
import Button from '../../component/Common/Button'
import { useReportInventoryService } from '../../services/ReportInventoryServices';
import { useLooksUpData } from '../../utils/getLookupTableData';
import moment from 'moment'
import * as XLSX from 'xlsx'
import Modal from 'react-modal';
import { useAccessControl } from '../../../src/utils/AccessControlContext';
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import _, { concat, create } from 'lodash'
import Cookies from 'js-cookie';
import { formatDateForDisplay, setDateInCST } from '../../utils/dateUtils';
import { savedFilters } from '../../App'

const Reminders = () => {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const ReportInventoryService = useReportInventoryService();
    const userType = sessionStorage.getItem('signedInUserType');
    const [finalMessageList, setFinalMessageList] = useState<AlertsResponseList[]>([])
    const [mcAlertList, setMcAlertList] = useState<any>([])
    const { getMasterTableLookupValueData, getMasterTableLookupData, getUserGroupTableLookupData, getOrganizationUnitsTableLookupData } = useLooksUpData();
    let [mcUserGroupsList, setMcUserGroupsList] = useState<any>([]);
    let [mcOrgUnitsList, setMcOrgUnitsGroupsList] = useState<any>([]);
    const { checkAccess } = useAccessControl();
    const [filteredReportResponseList, setFilteredReportResponseList] = useState<AlertsResponseList[]>([]);
    const loggedInUserDetails = JSON.parse(sessionStorage.getItem('loginUserDetails') || '{}');
    const isNonStateUser = loggedInUserDetails.organizationUnitCode !== "NEDHHS";
    let envPrefix = process.env.REACT_APP_ENV_PREFIX || '';
    const [filterDataList, setFilterDataList] = useState<AlertsResponseList[]>([]);
    const gridStyle = { minHeight: 622, width: '100%' };
    const [gridRef, setGridRef] = useState(null as any)

    interface AlertsResponseList {
        createdDate: string;
        instancePlanId: string;
        instanceName: string;
        reportingPeriod: string;
        alertId: string;
        alertDetails: string;
        organizationUnitCode: string
    }

    const fetchAllMctFinalMessageData = async (userId: any) => {
        const hardcodedGroup = `DHHS_AR_NEMCIS_MCO${envPrefix}-SUBMITTERS`;
        const mcoSubmitterProfile = sessionStorage.getItem('selectedProfile')
        try {
            setIsLoading(true);
            const response = await ReportInventoryService.getAllMctfinalmessage(userId);
            const lookupDataResponse = await getMasterTableLookupData();
            if (response && response.data && lookupDataResponse) {

                const sortedData = response.data.sort((a: any, b: any) => moment(b.createdDate).diff(moment(a.createdDate)));
                setFinalMessageList(sortedData);

                // let filteredMcPlan = lookupDataResponse.data.mc_plan.filter((item: { organizationUnitCode: string }) =>
                //     item.organizationUnitCode === loggedInUserDetails.organizationUnitCode
                // )

                // if (isNonStateUser) {
                //     const filteredData = response.data.filter((item: any) => {
                //         return item.planId === filteredMcPlan[0]?.planId;
                //     });

                //     const sortedData = filteredData.sort((a: any, b: any) => moment(b.createdDate).diff(moment(a.createdDate)));
                //     setFinalMessageList(sortedData);
                // } else if (mcoSubmitterProfile?.toLowerCase() === hardcodedGroup?.toLowerCase()) {
                //     const filteredData = response.data.filter((item: any) => {
                //         return item.planId === '7600';
                //     });

                //     const sortedData = filteredData.sort((a: any, b: any) => moment(b.createdDate).diff(moment(a.createdDate)));
                //     setFinalMessageList(sortedData);
                // } else {
                //     const sortedData = response.data.sort((a: any, b: any) => moment(b.createdDate).diff(moment(a.createdDate)));

                //     setFinalMessageList(sortedData);
                // }
            }
        } catch (error) {
            console.log('error', error);
        }
        finally { setIsLoading(false); }
    }

    const fetchMcAlertsDetailsData = async () => {
        try {
            const response = await ReportInventoryService.getAllMcAlertsDetails();
            if (response && response.data) {
                setMcAlertList(response.data);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    const fetchGetMcUserGroupTableLookupValueData = async () => {
        try {
            const response = await getUserGroupTableLookupData();
            if (response) {
                const { data } = response;

                const filterAndMapData = () => {
                    return data
                        // .filter((dt: any) => dt.userGroupCode === group)
                        .map((code: any) => ({
                            userGroupCode: `${code.userGroupCode}`,
                            userGroupName: code.userGroupName,
                            userGroupUsage: code.userGroupUsage,
                            oprtnlFlag: code.oprtnlFlag,
                        }));
                };

                setMcUserGroupsList(filterAndMapData);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchGetMcOrganizationUnitsTableLookupValueData = async () => {
        try {
            const response = await getOrganizationUnitsTableLookupData();
            if (response) {
                const { data } = response;

                const filterAndMapData = () => {
                    return data
                        // .filter((dt: any) => dt.userGroupCode === group)
                        .map((code: any) => ({
                            organizationUnitCode: `${code.organizationUnitCode}`,
                            organizationUnitName: code.organizationUnitName,
                            organizationUnitCtgryLkpcd: code.organizationUnitCtgryLkpcd,
                            oprtnlFlag: code.oprtnlFlag,
                            parentOrgUnitCode: code.parentOrgUnitCode
                        }));
                };

                setMcOrgUnitsGroupsList(filterAndMapData);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getMatchingMcAlert = (data: any) => {
        const matchedItem = mcAlertList.find((code: any) => code.alertId === parseInt(data));
        return matchedItem?.alertTitle || data;
    };

    const getMatchingMcAlertDetails = (data: any) => {
        const matchedItem = mcAlertList.find((code: any) => code.alertId === parseInt(data));
        return matchedItem?.alertTemplate || data;
    };

    const getMatchingOrgName = (data: any) => {
        const matchingValue = mcOrgUnitsList.find((code: any) => code.organizationUnitCode === data);
        return matchingValue?.organizationUnitName || data;

    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    useEffect(() => {
        const userId = loggedInUserDetails.userId;
        const fetchData = async () => {
            try {
                setIsLoading(true);
                await fetchMcAlertsDetailsData();
                await fetchGetMcUserGroupTableLookupValueData();
                await fetchGetMcOrganizationUnitsTableLookupValueData();
                await fetchAllMctFinalMessageData(userId);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const mapLookupValues = (item: any) => {
        return {
            ...item,
            createdDate: formatDateForDisplay(item.createdDate, 'MM/DD/YYYY h:mm:ss:SSS A'),
            alertTitle: getMatchingMcAlert(item?.alertId),
            alertDetails: getMatchingMcAlertDetails(item?.alertId),
            organizationUnitCode: getMatchingOrgName(item.organizationUnitCode),
            reportingPeriod: `${formatDateForDisplay(item.instanceStartDate, 'MM/DD/YYYY')} - ${formatDateForDisplay(item.instanceEndDate, 'MM/DD/YYYY')}`,
        };
    };

    const mappedData = useMemo(() => finalMessageList.map(mapLookupValues), [finalMessageList]);


    const filterValue = savedFilters.RemindersPage ? savedFilters.RemindersPage :
        [
            { name: 'createdDate', operator: 'contains', type: 'string', value: '' },
            { name: 'alertTitle', operator: 'contains', type: 'string', value: '' },
            { name: 'alertDetails', operator: 'contains', type: 'string', value: '' },
            { name: 'instancePlanId', operator: 'contains', type: 'string', value: '' },
            { name: 'instanceName', operator: 'contains', type: 'string', value: '' },
            { name: 'organizationUnitCode', operator: 'contains', type: 'string', value: '' },
            { name: 'reportingPeriod', operator: 'contains', type: 'string', value: '' },
        ]

    // Debounce hook to delay filter execution
    const useDebouncedValue = (value: any, delay = 300) => {
        const [debouncedValue, setDebouncedValue] = useState(value);
        useEffect(() => {
            const handler = setTimeout(() => setDebouncedValue(value), delay);
            return () => clearTimeout(handler);
        }, [value, delay]);
        return debouncedValue;
    };

    // Cached and memoized filter values
    const debouncedFilterValue = useDebouncedValue(filterValue, 300);

    // Filter records from the last 30 days
    const filteredReportResponseList1 = useMemo(() => {
        if (!mappedData.length) return [];

        const last30Days = new Date();
        last30Days.setDate(last30Days.getDate() - 30);

        return mappedData.filter((item: any) => {
            const recordDate = new Date(item.createdDate);
            return recordDate >= last30Days;
        });
    }, [mappedData]);

    // Update the filtered list when mapped data changes
    useEffect(() => {
        setFilteredReportResponseList(filteredReportResponseList1);
    }, [filteredReportResponseList1]);

    // Handle filter value change
    const handleFilterChangeGrid = useCallback((ev: any) => {
        savedFilters.RemindersPage = ev;
        setFilterDataList([]);
    }, []);

    // Normalize and filter records based on filter values
    const applyFilters = useCallback(() => {
        if (!debouncedFilterValue.length) {
            setFilterDataList(mappedData);
            return;
        }

        const normalizedFilters = debouncedFilterValue.map(({ name, value }: any) => ({
            name,
            value: typeof value === 'string' ? value.toLowerCase() : '',
        }));

        const filteredData = mappedData.filter((item: any) => {
            return normalizedFilters.every(({ name, value }: any) => {
                const itemValue = item[name];
                if (!itemValue || typeof itemValue !== 'string') return false;

                return itemValue.toLowerCase().includes(value);
            });
        });

        // Update state only if data has changed
        setFilterDataList((prevData) =>
            JSON.stringify(prevData) === JSON.stringify(filteredData) ? prevData : filteredData
        );
    }, [mappedData, debouncedFilterValue]);

    // Apply filters on data or filter changes
    useEffect(() => {
        applyFilters();
    }, [applyFilters]);

    const remindersListHeaders: any[] = [
        {
            name: 'createdDate',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Date
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
            render: ({ data }: { data: any }) => {
                return formatDateForDisplay(data.createdDate, 'MM/DD/YYYY h:mm:ss:SSS A');
            }
        },
        {
            name: 'alertTitle',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Alert
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 140,
            render: ({ data }: { data: any }) => {
                return getMatchingMcAlert(data.alertId);
            }
        },
        {
            name: 'alertDetails',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Alert Details
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 250,
            render: ({ data }: { data: any }) => {
                return getMatchingMcAlertDetails(data.alertId);
            }
        },
        {
            name: 'instancePlanId',
            header:
                (
                    <div className='grid-header-custom-style'>
                        MCO
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            maxWidth: 200,
        },
        {
            name: 'instanceName',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Report Name
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 200,
        },
        {
            name: 'reportingPeriod',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Reporting Period
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            maxWidth: 200,
            render: ({ data }: { data: any }) => {
                const beginDate = formatDateForDisplay(data.instanceStartDate, 'MM/DD/YYYY');
                const endDate = formatDateForDisplay(data.instanceEndDate, 'MM/DD/YYYY');

                return `${beginDate} - ${endDate}`;
            }
        },
        {
            name: 'organizationUnitCode',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Reviewer Group
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 200,
            render: ({ data }: { data: any }) => {
                return getMatchingOrgName(data.organizationUnitCode);
            }
        },
    ]

    const handleRowClick = (data: any) => {
        history.push({
            // pathname: '/contract-report-details',
            state: { recordData: data }
        });
    }

    const headers = [
        'Date',
        'Alert',
        'Alert Details',
        'MCO',
        'Report Name',
        "Reviewer Group",
        'Reporting Period'
    ];

    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [selectedHeaders, setSelectedHeaders] = useState<string[]>(headers);
    const toggleModal = () => {
        setSelectedHeaders(headers);
        setIsExportModalOpen(!isExportModalOpen);
    };

    const handleHeaderChange = (header: string) => {
        setSelectedHeaders((prev) =>
            prev.includes(header) ? prev.filter((h) => h !== header) : [...prev, header]
        );
    };

    const handleSelectAllChange = () => {
        setSelectedHeaders(selectedHeaders.length === headers.length ? [] : headers);
    };

    const handleExportToExcel = () => {
        const dataToExport = filterDataList.map((item: any) => {
            const row: { [key: string]: any } = {};
            selectedHeaders.forEach((header) => {
                row[header] = {
                    "Date": item.createdDate,
                    "Alert": getMatchingMcAlert(item.alertId),
                    "Alert Details": getMatchingMcAlertDetails(item.alertId),
                    "MCO": item.instancePlanId,
                    "Report Name": item.instanceName,
                    "Reviewer Group": getMatchingOrgName(item.organizationUnitCode),
                    "Reporting Period": `${formatDateForDisplay(item.instanceStartDate, 'MM/DD/YYYY')} - ${formatDateForDisplay(item.instanceEndDate, 'MM/DD/YYYY')}`
                }[header];
            });
            return row;
        });
        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const headerRow = selectedHeaders.map((header) => header);
        XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: 'A1' });

        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Reminders and Alerts');
        XLSX.writeFile(wb, 'reminders_alerts.xlsx');
        setIsExportModalOpen(false);
    }


    const [selectedOption1, setSelectedOption1] = useState(
        (savedFilters?.RemindersPage as any)?.selectedOption1 ||
        (null as any),
    )

    const [selectedOption2, setSelectedOption2] = useState(
        (savedFilters?.RemindersPage as any)?.selectedOption2 ||
        (null as any),
    )

    const [filter1Text, setFilter1Text] = useState<any>(null);
    const [filter2Text, setFilter2Text] = useState<any>(null);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const validateSearchBtn = () => {
        if (!(_.isEmpty(selectedOption1) || _.isEmpty(selectedOption2) || _.isEmpty(filter1Text) || _.isEmpty(filter2Text))) {
            return false;
        }
        return true;
    };

    const serachDropdownChoices1 = [
        {
            label: 'User Group',
            value: 'user_group_list',
        },
        {
            label: 'Reviewer Group',
            value: 'review_group_list',
        }
    ]

    const serachDropdownChoices2 = [
        {
            label: 'User Group',
            value: 'user_group_list',
        },
        {
            label: 'Reviewer Group',
            value: 'review_group_list',
        }
    ]

    function handleFilterChange1(event: any) {
        const selectedValue = event.target.value;
        setSelectedOption1(selectedValue);
        setIsDisabled(validateSearchBtn());
        setFilter1Text('');
    }

    function handleFilterChange2(event: any) {
        const selectedValue = event.target.value;
        setSelectedOption2(selectedValue);
        setIsDisabled(validateSearchBtn());
        setFilter2Text('');
    }

    function handleFilterText1(event: any) {
        const { value } = event.target;
        setFilter1Text(value);
        setIsDisabled(validateSearchBtn());
    }

    function handleFilterText2(event: any) {
        const enteredValue = event.target.value;
        setFilter2Text(enteredValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleClear() {
        sessionStorage.removeItem('savedFilters')
        setFilter1Text('');
        setFilter2Text('');
        setSelectedOption1(null);
        setSelectedOption2(null);
        setFilteredReportResponseList(mappedData);
    }

    useEffect(() => {
        // Check if saved filters exist in sessionStorage
        const savedFilters = sessionStorage.getItem('savedFilters');
        if (savedFilters) {
            const parsedFilters = JSON.parse(savedFilters);
            setSelectedOption1(parsedFilters.selectedOption1);
            setSelectedOption2(parsedFilters.selectedOption2);
            setFilter1Text(parsedFilters.filter1Text);
            setFilter2Text(parsedFilters.filter2Text);
        }
    }, []);

    async function handleSearch() {
        const filtersToSave = {
            selectedOption1: selectedOption1 || '',
            selectedOption2: selectedOption2 || '',
            filter1Text: filter1Text || '',
            filter2Text: filter2Text || ''
        };

        // Only save if the filters have changed
        const currentFilters = JSON.parse(sessionStorage.getItem('savedFilters') || '{}');
        if (JSON.stringify(currentFilters) !== JSON.stringify(filtersToSave)) {
            sessionStorage.setItem('savedFilters', JSON.stringify(filtersToSave));
        }

        let filter1Value: string | number | null = filter1Text;
        let filter2Value: string | number | null = filter2Text;

        if (selectedOption1 === 'user_group_list') {
            const matchingValue = mcUserGroupsList.find((code: any) => code.userGroupName.toLowerCase().includes((filter1Text || '').toLowerCase()));
            filter1Value = matchingValue ? matchingValue.userGroupCode : filter1Text;
        } else if (selectedOption1 === 'review_group_list') {
            const matchingValue = mcOrgUnitsList.find((code: any) => code.organizationUnitName.toLowerCase().includes((filter1Text || '').toLowerCase()));
            filter1Value = matchingValue ? matchingValue.organizationUnitCode : filter1Text;
        }

        if (selectedOption2 === 'user_group_list') {
            const matchingValue = mcUserGroupsList.find((code: any) => code.userGroupName.toLowerCase().includes((filter2Text || '').toLowerCase()));
            filter2Value = matchingValue ? matchingValue.userGroupCode : filter2Text;
        } else if (selectedOption2 === 'review_group_list') {
            const matchingValue = mcOrgUnitsList.find((code: any) => code.organizationUnitName.toLowerCase().includes((filter2Text || '').toLowerCase()));
            filter2Value = matchingValue ? matchingValue.organizationUnitCode : filter2Text;
        }

        const reqBody: any = {};

        if (selectedOption1 === 'user_group_list' && filter1Value) {
            reqBody.userGroupCode = filter1Value;
        } else if (selectedOption1 === 'review_group_list' && filter1Value) {
            reqBody.reviewGroupCode = filter1Value;
        }

        if (selectedOption2 === 'user_group_list' && filter2Value) {
            reqBody.userGroupCode = filter2Value;
        } else if (selectedOption2 === 'review_group_list' && filter2Value) {
            reqBody.reviewGroupCode = filter2Value;
        }

        try {
            const response = await ReportInventoryService.getAllWithUserGroupCode(reqBody);
            const recordIds = response.data.map((item: any) => item.mctFinalMessageSid);

            if (recordIds.length === 0) {
                setFilteredReportResponseList([]);
            } else {
                const filteredList = finalMessageList.filter((item: any) => recordIds.includes(item.mctFinalMessageSid));
                setFilteredReportResponseList(filteredList);
            }
        } catch (error) {
            setFilteredReportResponseList([]);
        }
    }

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="Reminders and Alerts" />
                </div>
            </div>

            <div className="form-layout">
                <div className="filters-row grid-cols-1 gap-4 md:grid-cols-2 lg:flex lg:justify-between lg:flex-wrap lg:items-center">
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <Dropdown
                            choices={serachDropdownChoices1}
                            emptyChoiceLabel="Select Filter Criteria 1"
                            name="filterCriteria1"
                            label=""
                            data-testid="payment_type_testid"
                            value={selectedOption1 ? selectedOption1 : ''}
                            onChange={handleFilterChange1}
                        />
                    </div>
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <InputText
                            context="registration"
                            name="search"
                            placeholder="Filter 1"
                            value={filter1Text || ''}
                            onChange={handleFilterText1}
                            small={true}
                        />
                    </div>
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <Dropdown
                            choices={serachDropdownChoices2}
                            emptyChoiceLabel="Select Filter Criteria 2"
                            name="filterCriteria2"
                            label=""
                            data-testid="payment_type_testid"
                            value={selectedOption2 ? selectedOption2 : ''}
                            onChange={handleFilterChange2}
                            disabled={(!selectedOption1 && !filter1Text) || filter1Text === '' || filter1Text === null}
                        />
                    </div>
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <InputText
                            context="registration"
                            name="search"
                            placeholder="Filter 2"
                            value={filter2Text || ''}
                            onChange={handleFilterText2}
                            small={true}
                            disabled={(!selectedOption1 && !filter1Text) || filter1Text === '' || filter1Text === null}
                        />
                    </div>
                    <div className="external-filters-action flex justify-end w-full lg:w-auto" style={{ marginBottom: '-20px' }}>
                        <Button
                            disabled={
                                !(
                                    (selectedOption1 && filter1Text) ||
                                    (selectedOption2 && filter2Text)
                                )
                            }
                            className="OkButton"
                            onClick={() => handleClear()}
                        >
                            Clear
                        </Button>
                        <Button
                            disabled={
                                !(
                                    (selectedOption1 && filter1Text) ||
                                    (selectedOption2 && filter2Text)
                                )
                            }
                            className="OkButton"
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                        <Button
                            className="OkButton"
                            onClick={toggleModal}
                            disabled={!mapLookupValues || mapLookupValues.length === 0 ||
                                checkAccess('report_master_detail_page', 'NOAC')
                            }
                        >
                            Export to Excel
                        </Button>
                    </div>
                </div>


                {/* <div className="external-filters-action flex justify-end w-full lg:w-auto" >
                        <Button
                            className="OkButton"
                            onClick={toggleModal}
                        disabled={!mapLookupValues || mapLookupValues.length === 0 ||
                            checkAccess('report_master_detail_page', 'VWNA') ||
                            checkAccess('report_master_detail_page', 'NOAC')
                        }
                        >
                            Export to Excel
                        </Button>
                    </div> */}

                <Modal
                    isOpen={isExportModalOpen}
                    onRequestClose={toggleModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Export data"
                    style={{
                        content: {
                            width: '500px',
                            height: '500px',
                            margin: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        },
                    }}
                >
                    <h2 style={{ marginBottom: '20px' }}><b>Select Fields for Export</b></h2>
                    <div>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <input
                                type="checkbox"
                                id="selectAll"
                                checked={selectedHeaders.length === headers.length}
                                onChange={handleSelectAllChange}
                                style={{ width: '15px', height: '15px', marginRight: '10px' }}
                            />
                            <label htmlFor="selectAll" style={{ verticalAlign: 'middle' }}>Select All</label>
                        </div>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                            {headers.map((header) => (
                                <div key={header} style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="checkbox"
                                        id={header}
                                        checked={selectedHeaders.includes(header)}
                                        onChange={() => handleHeaderChange(header)}
                                        style={{ width: '15px', height: '15px', marginRight: '10px' }}
                                    />
                                    <label htmlFor={header} style={{ verticalAlign: 'middle' }}>{header}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                        <button
                            disabled={selectedHeaders.length === 0}
                            className='usa-button'
                            onClick={handleExportToExcel}
                        >
                            Download Excel
                        </button>
                        <button
                            className='usa-button'
                            onClick={toggleModal}
                        >
                            Close
                        </button>
                    </div>
                </Modal>
                {isLoading ? (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                        <p>Loading.....</p>
                    </div>
                ) :
                    <>
                        <ReactDataGrid
                            licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                            idProperty="key"
                            style={gridStyle}
                            pagination
                            columns={remindersListHeaders}
                            dataSource={filteredReportResponseList}
                            sortable={true}
                            onFilterValueChange={handleFilterChangeGrid}
                            defaultLimit={10}
                            defaultFilterValue={filterValue}
                            rowHeight={50}
                            // onRowClick={({ data }) => handleRowClick(data)}
                            // className="pointer-cursor-row"
                            enableColumnAutosize={true}
                            handle={setGridRef}
                            pageSizes={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                        />
                    </>
                }
            </div>
            <Footer />
        </>
    );
}
export default Reminders;