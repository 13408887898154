import React, { useRef, useState, useEffect } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory } from 'react-router-dom'
import './upload-report-home.css'
import PageSubTitle from '../../component/Common/PageSubTitle'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import FormInput from '../../component/Common/FormInput'
import MultiSelectDropdown from '../../component/Common/CheckboxDropdown'
import ReactQuill from 'react-quill'
import moment from 'moment'
import 'react-quill/dist/quill.snow.css';
import { useReportInventoryService } from '../../services/ReportInventoryServices';
import { useLooksUpData } from '../../../src/utils/getLookupTableData';
import { includes } from 'lodash';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useAccessControl } from '../../../src/utils/AccessControlContext';
import { formatDateForDisplay, setDateInCST } from '../../utils/dateUtils';
import { report } from 'process';
import { Success_Messages, Warning_Messages, Error_Messages } from '../../utils/statusConstantValues';

const AdhocOrNewOnDemandReport = () => {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const ReportInventoryService = useReportInventoryService();
    const loggedInUserDetails = JSON.parse(sessionStorage.getItem('loginUserDetails') || '{}');
    const [isLoading, setIsLoading] = useState(false)
    const [isFormValid, setIsFormValid] = useState<boolean>(false)
    const [fileName, setFileName] = useState('');
    const [reportName, setReportName] = useState('');
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [selectedMCONameDropdown, setSelectedMCONameDropdown] = useState('');
    const [selectGroup, setSelectGroup] = useState('');
    const [uploadNewReport, setUploadNewReport] = useState<any>({
        planId: '',
        programReportSid: '',
        reportName: '',
        stateReferenceNumber: '',
        reportSbmsnModeLkpcd: '',
        reportType: '',
        periodBeginDate: '',
        periodEndDate: '',
        latestDueDate: '',
        notesSummary: '',
        // lastSubmsnStatusLkpcd: '10', // default value 10(Scheduled) 
        currentGroup: '',
        // currentSubmissionNumber: '0',
        lastValidationStatusLkpcd: 'A',
        lastReviewStatusLkpcd: '',
        oprtnlFlag: 'A',
        lastSubmsnDate: formatDateForDisplay(new Date(), 'YYYY-MM-DDTHH:mm:ss'),
        originalDueDate: '',
        createdBy: loggedInUserDetails.userEmail || 'nemcis@nebraska.gov',
        createdDate: formatDateForDisplay(new Date(), 'YYYY-MM-DDTHH:mm:ss'),
        updatedBy: loggedInUserDetails.userEmail || 'nemcis@nebraska.gov',
        updatedDate: formatDateForDisplay(new Date(), 'YYYY-MM-DDTHH:mm:ss'),
        activityStartDateTime: '',
        activityEndDateTime: '',
    });
    const [reportNameOptions, setReportNameOptions] = useState<any[]>([]);
    const [reporPlanOptions, setReportPlanOptions] = useState<any[]>([]);
    const [reportTypeList, setReportTypeList] = useState<any>([])
    const [submissionModeList, setSubmissionModeList] = useState<any>([])
    const [currentDueDate, setCurrentDueDate] = useState<string>('');
    const [periodEndDate, setPeriodEndDate] = useState<string>('');
    const [periodStartDate, setPeriodStartDate] = useState<string>('');
    const [dueDateErrorMessage, setDueDatedueDateErrorMessage] = useState<string>('');
    const [startDatedueDateErrorMessage, setStartDatedueDateErrorMessage] = useState<string>('');
    const [endDatedueDateErrorMessage, setEndDatedueDateErrorMessage] = useState<string>('');
    const [csInfoMessage, setCsInfoMessage] = useState<string>('');
    const [listOfExistingReports, setListOfExistingReports] = useState<
        { reportName: string; periodStart: string; periodEnd: string, reportType: string, planId: string }[]>([]);
    const [isReportNameExists, setIsReportNameExists] = useState(false);
    const { getMasterTableLookupValueData, getMasterTableLookupData } = useLooksUpData();
    const { checkAccess } = useAccessControl();
    const isNonStateUser = loggedInUserDetails.organizationUnitCode !== "NEDHHS";

    const userType = sessionStorage.getItem('signedInUserType');
    const fetchGetMasterTableLookupData = async () => {
        try {
            getMasterTableLookupData().then((response: any) => {
                if (response) {

                    let filteredMcPlan;
                    if (loggedInUserDetails.organizationUnitCode === "NEDHHS") {
                        filteredMcPlan = response.data.mc_plan;
                    } else {
                        filteredMcPlan = response.data.mc_plan.filter((item: { organizationUnitCode: string }) =>
                            item.organizationUnitCode === loggedInUserDetails.organizationUnitCode
                        );
                    }

                    const mcPlanOptions = filteredMcPlan.map((item: { planId: string, planName: string }) => ({
                        value: item.planId,
                        label: item.planName,
                    }));

                    if (loggedInUserDetails.organizationUnitCode !== "NEDHHS" && userType === "HealthPlanUser") {
                        setUploadNewReport((prevState: any) => {
                            return {
                                ...prevState,
                                planId: [mcPlanOptions[0].value]
                            };
                        });
                        setSelectedValues([mcPlanOptions[0].value]);
                    }

                    // const mcReportName = response.data.mc_program_reports.map((dt: any) => dt.reportName)
                    // setListOfExistingReports(mcReportName);

                    const mcReportingTypeLkpcd = response.data.mc_program_reports.filter(
                        (dt: any) => dt.reportingTypeLkpcd === 'D'
                    );

                    const mcProgramReportOptions = mcReportingTypeLkpcd.map((item: {
                        programReportSid: string, reportName: string,
                        stateReferenceNumber: string, reportingTypeLkpcd: string, dueDatePositionLkpcd: string
                    }) => ({
                        value: item.programReportSid,
                        label: item.reportName,
                        stateReferenceNumber: item.stateReferenceNumber,
                        reportingTypeLkpcd: item.reportingTypeLkpcd,
                        dueDatePositionLkpcd: item.dueDatePositionLkpcd
                    }));

                    setReportPlanOptions(mcPlanOptions);
                    setReportNameOptions(mcProgramReportOptions);
                }

            })

        } catch (e) {
            console.log(e);
        }
    }

    const fetchGetMasterTableLookupValueData = async () => {
        try {
            getMasterTableLookupValueData().then((response: any) => {
                if (response) {

                    const mcReportType = response.data.filter(
                        (dt: any) => dt.column_name.toLowerCase() === 'reporting_type_lkpcd' && dt.lkpcd_group === 'AD'
                    )

                    const mcSubmisstionMode = response.data.filter(
                        (dt: any) => dt.lkpcd_group === 'AD' && dt.table_name === 'MC_PROGRAM_REPORT' && dt.column_name === 'REPORT_SBMSN_MODE_LKPCD'
                    )

                    const dropdown = mcReportType.map((code: any) => {
                        return {
                            label: `${code.lkdmn_lkpcd_desc}`,
                            value: code.lkpdmn_lkpcd,
                            group: code.lkpcd_group
                        }
                    })

                    const mcSubMode = mcSubmisstionMode.map((code: any) => {
                        return {
                            value: `${code.lkpdmn_lkpcd}`,
                            label: code.lkdmn_lkpcd_desc,
                            lkpcd_group: code.lkpcd_group,
                        }
                    })

                    setReportTypeList(dropdown);
                    setSubmissionModeList(mcSubMode);
                }
            })

        } catch (e) {
            console.log(e);
        }
    }

    const fetchReportInventoryData = async () => {
        try {
            const response = await ReportInventoryService.getReportInventory();
            const lookupDataResponse = await getMasterTableLookupData();

            if (response && lookupDataResponse) {
                // Filter based on organizationUnitCode
                let filteredMcPlan = lookupDataResponse.data.mc_plan.filter(
                    (item: { organizationUnitCode: string }) =>
                        item.organizationUnitCode === loggedInUserDetails.organizationUnitCode
                );

                // Helper function to extract the required fields
                const extractReportData = (data: any[]) =>
                    data.map(({ report_name, period_begin_date, period_end_date, reporting_type_lkpcd, plan_id }) => ({
                        reportName: report_name,
                        periodStart: period_begin_date,
                        periodEnd: period_end_date,
                        reportType: reporting_type_lkpcd,
                        planId: plan_id

                    }));

                let filteredData;
                if (!accounts?.[0]?.localAccountId && userType === "HealthPlanUser") {
                    filteredData = response.data.filter(
                        (item: any) => item.plan_id === filteredMcPlan[0]?.planId
                    );
                } else {
                    filteredData = response.data;
                }

                // Set only the required fields in the state
                setListOfExistingReports(extractReportData(filteredData));
            }
        } catch (e) {
            console.error(e);
        }
    };


    useEffect(() => {
        uploadNewReport.activityStartDateTime = formatDateForDisplay(new Date(), 'YYYY-MM-DDTHH:mm:ss')
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await Promise.all([
                fetchGetMasterTableLookupData(),
                fetchGetMasterTableLookupValueData(),
                fetchReportInventoryData()
            ]);
            setIsLoading(false);
        };

        fetchData();
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (isNonStateUser) {

                const selectedReportTypeObj = reportTypeList.find((code: any) => uploadNewReport.reportType.includes(code.value));
                let newGroup = '';
                if (selectedReportTypeObj) {
                    newGroup = selectedReportTypeObj.group;
                    setSelectGroup(newGroup)
                }

                setUploadNewReport((prevState: any) => {
                    return {
                        ...prevState,
                        reportType: 'D',
                    };
                })

            }
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [userType, reportTypeList, reportNameOptions, uploadNewReport.reportType]);

    useEffect(() => {
        if (uploadNewReport.programReportSid !== null) {
            const selectedReportNmeObj = reportNameOptions.find((code: any) => uploadNewReport.programReportSid === code.value);
            let selectedReportName = selectedReportNmeObj?.label;
            uploadNewReport.reportName = selectedReportName;
        } else {
            uploadNewReport.reportName = uploadNewReport.reportName;
        }
    }, [uploadNewReport.programReportSid])

    function validateForm(): void {
        if (loggedInUserDetails.organizationUnitCode === "NEDHHS") {
            if (uploadNewReport.reportType === 'D') {
                if (
                    !uploadNewReport.reportType ||
                    selectedValues.length === 0 ||
                    !uploadNewReport.programReportSid ||
                    !uploadNewReport.latestDueDate ||
                    !uploadNewReport.periodBeginDate ||
                    !uploadNewReport.periodEndDate ||
                    !uploadNewReport.reportSbmsnModeLkpcd ||
                    !uploadNewReport.notesSummary
                ) {
                    setIsFormValid(false)
                } else {
                    setIsFormValid(true)
                }

            } else {
                if (
                    !uploadNewReport.reportType ||
                    selectedValues.length === 0 ||
                    !uploadNewReport.reportName ||
                    !uploadNewReport.latestDueDate ||
                    !uploadNewReport.periodBeginDate ||
                    !uploadNewReport.periodEndDate ||
                    !uploadNewReport.reportSbmsnModeLkpcd ||
                    !uploadNewReport.notesSummary
                ) {
                    setIsFormValid(false)
                } else {
                    setIsFormValid(true)
                }
            }
        }
        else if (
            !uploadNewReport.reportType ||
            !uploadNewReport.programReportSid ||
            !uploadNewReport.latestDueDate ||
            !uploadNewReport.periodBeginDate ||
            !uploadNewReport.periodEndDate ||
            !uploadNewReport.reportSbmsnModeLkpcd ||
            !uploadNewReport.notesSummary
        ) {
            setIsFormValid(false)
        } else {
            setIsFormValid(true)
        }
    }

    useEffect(() => {
        validateForm()
    }, [uploadNewReport, selectedValues])

    function validateReportName(): boolean {
        // Helper to convert "MM/DD/YYYY" to "YYYY-MM-DD"
        const convertToISODate = (date: string) => {
            const [month, day, year] = date.split('/');
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        };

        // Check if a duplicate report exists in the same record
        const reportExists = listOfExistingReports.some((report) => {
            const reportStartISO = convertToISODate(report?.periodStart);
            const reportEndISO = convertToISODate(report?.periodEnd);

            // 1. Ensure planId, reportType, and reportName all match
            const isSameRecord =
                selectedValues?.includes(report?.planId) &&
                uploadNewReport?.reportType === report?.reportType &&
                reportName?.toLowerCase().trim() === report?.reportName?.toLowerCase().trim()

            // 2. Check if the reporting period overlaps
            const isOverlapping =
                (uploadNewReport.periodBeginDate >= reportStartISO &&
                    uploadNewReport.periodBeginDate <= reportEndISO) ||
                (uploadNewReport.periodEndDate >= reportStartISO &&
                    uploadNewReport.periodEndDate <= reportEndISO);

            return isSameRecord && isOverlapping; // Ensure all conditions match within the same record
        });

        setIsReportNameExists(reportExists);
        return !reportExists; // Return true if no duplicate found
    }

    useEffect(() => {
        if (!uploadNewReport.reportType.includes('S')) {
            validateReportName();
        }
    }, [
        uploadNewReport.reportName,
        uploadNewReport.periodBeginDate,
        uploadNewReport.periodEndDate,
        uploadNewReport.planId,
        uploadNewReport.reportType,
        uploadNewReport.programReportSid,
        selectedValues,
        reportName
    ]);

    function handleInputData(event: any) {
        const { name, value } = event.target;
        const selectedValue = event.target.value;
        const selectedReportTypeObj = reportTypeList.find((code: any) => selectedValue.includes(code.value));
        let newGroup = '';

        if (name === 'reportName') {
            setReportName(value)
        }
        if (selectedReportTypeObj) {
            newGroup = selectedReportTypeObj.group;
            setSelectGroup(newGroup)
        }
        if (isNonStateUser) {
            if (name === 'reportType' && value === 'A') {
                setUploadNewReport((prevState: any) => {
                    return {
                        ...prevState,
                        programReportSid: '',

                    };
                });
            }
            if (name === 'reportType' && value === 'D') {
                const newProgramReportSid = 7;
                const matchingReport = reportNameOptions.find((code: any) => code.value === newProgramReportSid);
                setUploadNewReport((prevState: any) => {
                    return {
                        ...prevState,
                        programReportSid: newProgramReportSid,
                        [name]: value,
                        stateReferenceNumber: matchingReport ? matchingReport.stateReferenceNumber : '',
                        reportSbmsnModeLkpcd: 'W'
                    };
                });
            } else if (name === 'reportType' && value === 'A') {
                setUploadNewReport((prevState: any) => ({
                    ...prevState,
                    programReportSid: '',
                    stateReferenceNumber: '',
                    reportName: '',
                    reportSbmsnModeLkpcd: 'W',
                    [name]: value
                }));
            }
        } else {
            setUploadNewReport((prevState: any) => ({
                ...prevState,
                [name]: value
            }));
        }

        // if (name === 'reportName') {
        //     validateReportName(value);
        // }
    }


    function handleReportNameDropdownData(event: any) {
        const { name, value } = event.target;
        const matchingReport = reportNameOptions.find((code: any) => code.value === parseInt(value));

        setReportName(matchingReport ? matchingReport.label : '')
        setCsInfoMessage(matchingReport ? 'The date fields must all be the same date.' : '')

        setUploadNewReport((prevState: any) => {
            return {
                ...prevState,
                reportType: 'D',
                programReportSid: value,
                reportSbmsnModeLkpcd: 'W',
                stateReferenceNumber: matchingReport ? matchingReport.stateReferenceNumber : '',
                reportName: matchingReport ? matchingReport.label : '',
            };
        });
    }

    function handleDateInputChange(event: any) {
        const { name, value } = event.target
        setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }))
    }

    const handleDueDateChange = (event: any) => {
        const { name, value } = event.target;
        setCurrentDueDate(value);

        setStartDatedueDateErrorMessage('');
        setEndDatedueDateErrorMessage('')
        setDueDatedueDateErrorMessage('')

        if (uploadNewReport.reportType === 'D') {
            const matchingReport = reportNameOptions.find((code: any) => code.value === parseInt(uploadNewReport.programReportSid));
            const dueDatePosition = matchingReport?.dueDatePositionLkpcd;

            if (dueDatePosition === 'AP') {
                if (moment.utc(value).isSameOrBefore(moment.utc(periodEndDate))) {
                    setDueDatedueDateErrorMessage('This report is always due after its Reporting Period as per the Due Date position setup in the report master.');
                } else {
                    setDueDatedueDateErrorMessage('');
                }
            } else if (dueDatePosition === 'PP') {
                if (moment.utc(value).isSameOrAfter(moment.utc(periodStartDate))) {
                    setDueDatedueDateErrorMessage('DUE PP This report is always due before its Reporting Period as per the Due Date position setup in the report master.');
                } else {
                    setDueDatedueDateErrorMessage('');
                }
            } else if (dueDatePosition === 'DP') {
                if (moment.utc(value).isBefore(moment.utc(periodStartDate)) || moment.utc(value).isAfter(moment.utc(periodEndDate))) {
                    setDueDatedueDateErrorMessage('This report is always due during its Reporting Period as per the Due Date position setup in the report master.');
                } else {
                    setDueDatedueDateErrorMessage('');
                }
            }
        }
        setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }));
    };

    const handlePeriodStartDateChange = (event: any) => {
        const { name, value } = event.target;
        setPeriodStartDate(value);

        setStartDatedueDateErrorMessage('');
        setEndDatedueDateErrorMessage('')
        setDueDatedueDateErrorMessage('')

        // Validate that the end date is not earlier than the start date
        if (moment.utc(value).isAfter(moment.utc(periodEndDate))) {
            setStartDatedueDateErrorMessage('Reporting Start Date can not be before the Reporting End Date');
            setEndDatedueDateErrorMessage('')
        } else {
            setStartDatedueDateErrorMessage('');
            setEndDatedueDateErrorMessage('')
        }

        // Validate due date based on conditions
        if (uploadNewReport.reportType === 'D') {
            const matchingReport = reportNameOptions.find((code: any) => code.value === parseInt(uploadNewReport.programReportSid));
            const dueDatePosition = matchingReport?.dueDatePositionLkpcd;

            if (dueDatePosition === 'PP') {
                if (moment.utc(currentDueDate).isSameOrBefore(moment.utc(value))) {
                    setDueDatedueDateErrorMessage('PPPPP This report is always due before its Reporting Period as per the Due Date position setup in the report master.');
                } else {
                    setDueDatedueDateErrorMessage('');
                }
            } else if (dueDatePosition === 'DP') {
                if (moment.utc(currentDueDate).isBefore(moment.utc(value)) || moment.utc(currentDueDate).isAfter(moment.utc(periodEndDate))) {
                    setDueDatedueDateErrorMessage('This report is always due during its Reporting Period as per the Due Date position setup in the report master.');
                } else {
                    setDueDatedueDateErrorMessage('');
                }
            }
        }

        setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }));
    };

    const handlePeriodEndDateChange = (event: any) => {
        const { name, value } = event.target;
        setStartDatedueDateErrorMessage('');
        setEndDatedueDateErrorMessage('')
        setDueDatedueDateErrorMessage('')
        const endDate = moment.utc(value, 'YYYY-MM-DD', true);
        const startDate = moment.utc(periodStartDate, 'YYYY-MM-DD', true);
        const dueDate = moment.utc(currentDueDate, 'YYYY-MM-DD', true);

        // Validate that the end date is not earlier than the start date
        if (endDate.isBefore(startDate)) {
            setEndDatedueDateErrorMessage('Reporting End Date can not be before the Reporting Start Date');
            setStartDatedueDateErrorMessage('');
        } else if (endDate.isSameOrAfter(dueDate)) {
            setDueDatedueDateErrorMessage('This report is always due after its Reporting Period as per the Due Date position setup in the report master.');
            setStartDatedueDateErrorMessage('');
        } else if (uploadNewReport.reportType === 'D' && endDatedueDateErrorMessage === '') {
            const matchingReport = reportNameOptions.find((code: any) => code.value === parseInt(uploadNewReport.programReportSid));
            const dueDatePosition = matchingReport?.dueDatePositionLkpcd;

            if (dueDatePosition === 'AP') {
                if (moment.utc(currentDueDate).isBefore(moment.utc(value))) {
                    setDueDatedueDateErrorMessage('This report is always due after its Reporting Period as per the Due Date position setup in the report master.');
                } else {
                    setDueDatedueDateErrorMessage('');
                }
            } else if (dueDatePosition === 'DP') {
                if (moment.utc(currentDueDate).isBefore(moment.utc(periodStartDate)) || moment.utc(currentDueDate).isBefore(moment.utc(value))) {
                    setDueDatedueDateErrorMessage('This report is always due during its Reporting Period as per the Due Date position setup in the report master');
                } else {
                    setDueDatedueDateErrorMessage('');
                }
            }
        } else {
            setEndDatedueDateErrorMessage('');
            setStartDatedueDateErrorMessage('');
        }

        setPeriodEndDate(value);

        setUploadNewReport((prevState: any) => ({ ...prevState, [name]: value }));
    };

    const handleRichTextChangeAppDetails = (event: any) => {
        const appDetails = event
        setUploadNewReport((prevState: any) => ({ ...prevState, ['notesSummary']: appDetails }))
    }

    function handleClear() {
        history.goBack()
    }

    async function handleSave() {
        setLoadingButton(true);
        uploadNewReport.activityEndDateTime = formatDateForDisplay(new Date(), 'YYYY-MM-DDTHH:mm:ss')

        const plainTextComments = uploadNewReport.notesSummary.replace(/<\/?[^>]+(>|$)/g, "");
        uploadNewReport.notesSummary = plainTextComments;

        if (loggedInUserDetails.organizationUnitCode === "NEDHHS") {
            if (uploadNewReport.reportType === 'D') {
                // const newProgramReportSid = 7;
                const matchingReport = reportNameOptions.find((code: any) => code.value === parseInt(uploadNewReport.programReportSid));
                const selectedReportTypeObj = reportTypeList.find((code: any) => uploadNewReport.reportType.includes(code.value));
                uploadNewReport.currentGroup = selectedReportTypeObj.group;
                uploadNewReport.period = `${moment(uploadNewReport.periodBeginDate).format('MM/DD/YYYY')} - ${moment(uploadNewReport.periodEndDate).format('MM/DD/YYYY')}`;
                uploadNewReport.planId = selectedValues,
                    // uploadNewReport.reportType = 'D',
                    // uploadNewReport.programReportSid = newProgramReportSid,
                    // uploadNewReport.stateReferenceNumber = matchingReport ? matchingReport.stateReferenceNumber : '',
                    // uploadNewReport.reportSbmsnModeLkpcd = 'W'
                    uploadNewReport.reportName = matchingReport ? matchingReport.label : '';
                try {
                    const response = await ReportInventoryService.saveMctreportinstance(uploadNewReport);
                    if (response) {
                        console.log('New Report data has been successfully uploaded');
                        toast.success(Success_Messages.New_Report_data_has_been_successfully_uploaded);
                        setTimeout(() => {
                            setLoadingButton(true);
                            history.push('/admin-dashboard');
                        }, 500);
                    }
                } catch (e) {
                    console.error('Error:', e);
                    toast.error(Error_Messages.Error_creating_report);
                } finally {
                    setLoadingButton(false);
                }

            } else {

                uploadNewReport.period = `${moment(uploadNewReport.periodBeginDate).format('MM/DD/YYYY')} - ${moment(uploadNewReport.periodEndDate).format('MM/DD/YYYY')}`;
                uploadNewReport.currentGroup = selectGroup;
                uploadNewReport.planId = selectedValues;
                uploadNewReport.programReportSid = '';
                const newReport = { ...uploadNewReport, planId: selectedValues };

                try {
                    const response = await ReportInventoryService.saveMctreportinstance(newReport);
                    if (response) {
                        console.log('New Report data has been successfully uploaded');
                        toast.success(Success_Messages.New_Report_data_has_been_successfully_uploaded);
                        setTimeout(() => {
                            setLoadingButton(true);
                            history.push('/admin-dashboard');
                        }, 500);
                    }
                } catch (e) {
                    console.error('Error:', e);
                    toast.error(Error_Messages.Error_creating_report);
                } finally {
                    setLoadingButton(false);
                }

            }
        } else {
            try {
                const matchingReport = reportNameOptions.find((code: any) => code.value === parseInt(uploadNewReport.programReportSid));

                uploadNewReport.period = `${moment(uploadNewReport.periodBeginDate).format('MM/DD/YYYY')} - ${moment(uploadNewReport.periodEndDate).format('MM/DD/YYYY')}`;
                uploadNewReport.currentGroup = selectGroup;
                uploadNewReport.planId = selectedValues;
                uploadNewReport.reportName = matchingReport ? matchingReport.label : '';
                const newReport = { ...uploadNewReport, planId: selectedValues };
                const response = await ReportInventoryService.saveMctreportinstance(newReport);
                const allSuccessful = response.status === 200;

                if (allSuccessful) {
                    toast.success(Success_Messages.All_reports_created_successfully)
                    setTimeout(() => {
                        history.push('/admin-dashboard');
                    }, 500);
                } else {
                    toast.error(Error_Messages.Some_reports_failed_to_create);
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error(Error_Messages.Error_creating_report);
            } finally {
                setLoadingButton(false);
            }
        }
    }

    const handleMultiDropdownChange = (selectedOptions: string[]) => {
        setSelectedValues(selectedOptions)
    }

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text={"Add Report"} />
                </div>
            </div>

            <div className="row1">
                <div className="element">
                    <Dropdown
                        // choices={reportTypeOptions}
                        choices={reportTypeList}
                        emptyChoiceLabel="Select Filter Criteria 1"
                        name="reportType"
                        label="Report Type"
                        data-testid="payment_type_testid"
                        value={uploadNewReport.reportType || ''}
                        onChange={handleInputData}
                        mandatoryLabel={isNonStateUser ? false : true}
                        disabled={isNonStateUser ? true : false}
                    />
                </div>
                <div className="element" >
                    {isNonStateUser ? (
                        <Dropdown
                            choices={reporPlanOptions}
                            emptyChoiceLabel="Select MCO Name"
                            name="planId"
                            label="MCO Name"
                            data-testid="mco-name"
                            value={uploadNewReport.planId || ''}
                            onChange={handleInputData}
                            mandatoryLabel={isNonStateUser ? false : true}
                            disabled={isNonStateUser ? true : false}
                        />
                    ) : (
                        <div className="usa-form-group" >
                            <MultiSelectDropdown
                                options={reporPlanOptions}
                                label="MCO Name"
                                name="planId"
                                onChange={handleMultiDropdownChange}
                                selectedValues={selectedValues}
                                mandatoryLabel={true}
                            />
                        </div>
                    )}

                </div>
            </div>
            <div className="row1">
                <div className="element" >
                    {uploadNewReport.reportType === 'A' ? (
                        <>
                            <InputText
                                context="registration"
                                label="Report Name"
                                name="reportName"
                                placeholder="Report Name"
                                value={uploadNewReport.reportName || ''}
                                onChange={handleInputData}
                                mandatoryLabel={true}
                            />
                            {isReportNameExists && (
                                <div style={{ color: 'red' }}>
                                    <p>A report already exists for this MCO with this name and period</p>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <Dropdown
                                choices={reportNameOptions}
                                emptyChoiceLabel="Select Filter Criteria 1"
                                name="programReportSid"
                                label="Report Name"
                                data-testid="payment_type_testid"
                                value={uploadNewReport.programReportSid || ''}
                                onChange={handleReportNameDropdownData}
                                mandatoryLabel={true}
                            />
                            {isReportNameExists && (
                                <div style={{ color: 'red' }}>
                                    <p>A report already exists for this MCO with this name and period</p>
                                </div>
                            )}
                            {csInfoMessage && (
                                <div style={{ color: 'blue' }}>
                                    <p>{csInfoMessage}</p>
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className="element" >
                    <InputText
                        context="registration"
                        label="Reference Number"
                        name="stateReferenceNumber"
                        placeholder="Reference Number"
                        value={uploadNewReport.stateReferenceNumber}
                        onChange={handleInputData}
                        // mandatoryLabel={true}
                        disabled={uploadNewReport.reportType === 'D' ? true : false}
                    // small={true}
                    />
                </div>
            </div>

            <div className="row1">
                <div className="element">
                    <div className="usa-form-group">
                        <FormInput
                            label="Reporting Start Date"
                            type="date"
                            name="periodBeginDate"
                            value={periodStartDate}
                            onChange={handlePeriodStartDateChange}
                            placeholder={'MM-DD-YYYY'}
                            id={''}
                            required={true}
                        />
                    </div>
                    {startDatedueDateErrorMessage && <div style={{ color: 'red' }}>{startDatedueDateErrorMessage}</div>}
                </div>
                <div className="element">
                    <div className="usa-form-group">
                        <FormInput
                            label="Reporting End Date"
                            type="date"
                            name="periodEndDate"
                            value={periodEndDate}
                            onChange={handlePeriodEndDateChange}
                            placeholder={'MM-DD-YYYY'}
                            id={''}
                            required={true}
                        />
                    </div>
                    {endDatedueDateErrorMessage && <div style={{ color: 'red' }}>{endDatedueDateErrorMessage}</div>}
                </div>
            </div>
            <div className="row1">
                <div className="element">
                    <div className="usa-form-group">
                        <FormInput
                            label="Due Date"
                            type="date"
                            name="latestDueDate"
                            value={currentDueDate}
                            onChange={handleDueDateChange}
                            placeholder={'MM-DD-YYYY'}
                            id={''}
                            required={true}
                        />
                    </div>
                    {dueDateErrorMessage && <div style={{ color: 'red' }}>{dueDateErrorMessage}</div>}
                </div>

                <div className="element">
                    <Dropdown
                        choices={submissionModeList}
                        emptyChoiceLabel="Select Channel"
                        name="reportSbmsnModeLkpcd"
                        label="Channel"
                        data-testid="payment_type_testid"
                        value={uploadNewReport.reportSbmsnModeLkpcd || ''}
                        onChange={handleInputData}
                        mandatoryLabel={true}
                    />
                </div>
            </div>

            <div className="row1" style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem', marginTop: '20px' }}>
                <label >
                    <b>User Comments:<span className="usa-mandatory-label">
                        *
                    </span>
                    </b>
                </label>
                <ReactQuill
                    style={{ maxHeight: '150px', overflowY: 'auto', width: '100%', borderColor: '#3e3e3f', borderWidth: '1px' }}
                    value={uploadNewReport.notesSummary}
                    onChange={handleRichTextChangeAppDetails}
                    modules={{
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'], // formatting options
                            [{ list: 'ordered' }, { list: 'bullet' }], // list options
                            [{ align: [] }], // alignment options
                            ['clean'], // remove formatting option
                        ],
                    }}
                />
            </div>

            <div className="row1">
                <div className="element" />
                <div className="external-filters-action flex justify-end w-full lg:w-auto lg:max-w-xs">
                    <div>
                        <Button
                            className="OkButton"
                            onClick={() => handleClear()}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!isFormValid || !!dueDateErrorMessage || isReportNameExists || !!startDatedueDateErrorMessage || !!endDatedueDateErrorMessage ||
                                checkAccess('add_inventory_report', 'NOAC') || checkAccess('add_inventory_report', 'VWNA')
                            }
                            className="OkButton"
                            onClick={handleSave}
                            loading={loadingButton}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );

}
export default AdhocOrNewOnDemandReport;
