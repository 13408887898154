import React, { createContext, useContext, useState, useEffect } from 'react';
import { useReportInventoryService } from '../services/ReportInventoryServices';
import Cookies from 'js-cookie';

const AccessControlContext = createContext();

const AccessControlProvider = ({ children }) => {
    const [permissions, setPermissions] = useState([]);
    const ReportInventoryService = useReportInventoryService();

    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const getSelectedProfile = sessionStorage.getItem('selectedProfile');
                if (getSelectedProfile) {
                    const response = await ReportInventoryService.getAllUserGroupsFromDBByUserGroupId(getSelectedProfile);
                    if (response) {
                        setPermissionsFromApiResponse(response.data);
                    }
                }
            } catch (error) {
                console.error('Failed to fetch permissions:', error);
            }
        };

        fetchPermissions();
    }, []);

    const setPermissionsFromApiResponse = (apiResponse) => {
        const permissions = apiResponse.reduce((acc, { pageName, accessTypeLkpcd }) => {
            acc[pageName] = accessTypeLkpcd.trim();
            return acc;
        }, {});
        setPermissions(permissions);
    };

    const checkAccess = (pageName, restrictedAccessTypes) => {
        const pagePermission = permissions[pageName];
        if (!pagePermission) return false;
    
        // Ensure restrictedAccessTypes is an array
        const accessArray = Array.isArray(restrictedAccessTypes) ? restrictedAccessTypes : [restrictedAccessTypes];
    
        // Check for exact match
        return accessArray.some(type => type === pagePermission);
    };
    
    

    return (
        <AccessControlContext.Provider value={{ setPermissionsFromApiResponse, checkAccess }}>
            {children}
        </AccessControlContext.Provider>
    );
};

const useAccessControl = () => {
    return useContext(AccessControlContext);
};

export { AccessControlProvider, useAccessControl };