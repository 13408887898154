import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory } from 'react-router-dom'
import './reference-home.css'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import PageSubTitle from '../../component/Common/PageSubTitle'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import _, { set } from 'lodash'
import * as XLSX from 'xlsx'
import Modal from 'react-modal';
import { useReportInventoryService } from '../../services/ReportInventoryServices';
import { useLooksUpData } from '../../../src/utils/getLookupTableData';
import moment from 'moment'
import { useAccessControl } from '../../../src/utils/AccessControlContext';
import Cookies from 'js-cookie';
import { formatDateForDisplay, setDateInCST } from '../../utils/dateUtils';
import { savedFilters } from '../../App'

const Reference = () => {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const ReportInventoryService = useReportInventoryService();
    const { getMasterTableLookupValueData, getOrganizationUnitsTableLookupData, getUserGroupTableLookupData } = useLooksUpData();
    const [frequencyLKPCDList, setFrequencyLKPCDList] = useState<any>([])
    const [channelLKPCDList, setChannelLKPCDList] = useState<any>([])
    const { checkAccess } = useAccessControl();
    const [filteredReportResponseList, setFilteredReportResponseList] = useState<ReferenceList[]>([]);
    const gridStyle = { minHeight: 550, width: '100%' };
    const [gridRef, setGridRef] = useState(null as any)
    let [filterData, setFilterData] = useState<ReferenceList[]>([]);
    const [mcProgramData, setMcProgramData] = useState<any>([]);
    const [selectedOption1, setSelectedOption1] = useState(
        (savedFilters?.ReferencePage as any)?.selectedOption1 ||
        (null as any),
    )
    const [selectedOption2, setSelectedOption2] = useState(
        (savedFilters?.ReferencePage as any)?.selectedOption2 ||
        (null as any),
    )
    const [filter1Text, setFilter1Text] = useState<string | null>(null);
    const [filter2Text, setFilter2Text] = useState<string | null>(null);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [reportTypeList, setReportTypeList] = useState<any>([])
    const [contentTypeList, setContentTypeList] = useState<any>([])
    const [reportGroupList, setReportGroupList] = useState<any>([])
    const [reportFileFormat, setReportFileFormat] = useState<any>([])
    const [businessStatusLKPCDList, setBusinessStatusLKPCDList] = useState<any>([])
    const [afStatusLKPCDList, setAfStatusLKPCDList] = useState<any>([])
    const [uploadTypeLKPCDList, setUploadTypeLKPCDList] = useState<any>([])
    const [checklistAfStatusLKPCDList, setChecklistAfStatusLKPCDList] = useState<any>([])
    const [mcoActionLKPCDList, setNcoActionLKPCDList] = useState<any>([])
    const [reviewerGroupLKPCDList, setReviewerGroupLKPCDList] = useState<any>([])
    let [reviewersGroupsList, setReviewersGroupsList] = useState<any>([]);
    let [mcUserGroupsList, setMcUserGroupsList] = useState<any>([]);
    let [mcOrgUnitsList, setMcOrgUnitsGroupsList] = useState<any>([]);
    const loggedInUserDetails = JSON.parse(sessionStorage.getItem('loginUserDetails') || '{}');
    const isNonStateUser = loggedInUserDetails.organizationUnitCode !== "NEDHHS";

    interface ReferenceList {
        reportName: string;
        reportingTypeLkpcd: string;
        stateReferenceNumber: string;
        reportingPeriodQlfrLkpcd: string;
        reportFileTypeLkpcd: string;
        reportSbmsnModeLkpcd: string;
        updatedDate: string;
        businessStatusLkpcd: string;
        programReportSid?: string;
        extensionDays?: number;
        programCode?: string;
        programName?: string;
        reportingContentTypeLkpcd?: string;
        autoValidationFlag?: string;
        certificationReqdFlag?: string;
        startDate?: string;
        endDate?: string;
    }

    const serachDropdownChoices1 = [
        {
            label: 'Program',
            value: 'programName',
        },
        {
            label: 'Report Content Type',
            value: 'reportingContentTypeLkpcd',
        },
        {
            label: 'Initial Verification Flag',
            value: 'autoValidationFlag',
        },
        {
            label: 'Need Certification Flag',
            value: 'certificationReqdFlag',
        },
        {
            label: 'Report Begin Date',
            value: 'startDate',
        },
        {
            label: 'Report End Date',
            value: 'endDate',
        },
        {
            label: 'Reviewer Group',
            value: 'reviewersGroup',
        }
    ]

    const serachDropdownChoices2 = [
        {
            label: 'Program',
            value: 'programName',
        },
        {
            label: 'Report Content Type',
            value: 'reportingContentTypeLkpcd',
        },
        {
            label: 'Initial Verification Flag',
            value: 'autoValidationFlag',
        },
        {
            label: 'Need Certification Flag',
            value: 'certificationReqdFlag',
        },
        {
            label: 'Report Begin Date',
            value: 'startDate',
        },
        {
            label: 'Report End Date',
            value: 'endDate',
        },
        {
            label: 'Reviewer Group',
            value: 'reviewersGroup',
        }
    ]

    const filterValue = savedFilters.ReferencePage ??
        [
            { name: 'reportName', operator: 'contains', type: 'string', value: '' },
            { name: 'reportingTypeLkpcd', operator: 'contains', type: 'string', value: '' },
            { name: 'stateReferenceNumber', operator: 'contains', type: 'string', value: '' },
            { name: 'reportingPeriodQlfrLkpcd', operator: 'contains', type: 'string', value: '' },
            { name: 'reportFileTypeLkpcd', operator: 'contains', type: 'string', value: '' },
            { name: 'reportSbmsnModeLkpcd', operator: 'contains', type: 'string', value: '' },
            { name: 'businessStatusLkpcd', operator: 'contains', type: 'string', value: '' },
            { name: 'updatedDate', operator: 'contains', type: 'string', value: '' },
        ];
    const userType = sessionStorage.getItem('signedInUserType');

    const fetchAllProgramReportData = async () => {
        setIsLoading(true);
        try {
            const response = await ReportInventoryService.getMcProgramReport();
            if (response) {
                const mcProgramReports = response.data.mcProgramReports;
                const mcPrograms = response.data.mcPrograms;
                const mcReports = response.data.mcReports;

                const matchingRecords = mcProgramReports.map((report: any) => {
                    const matchingProgram = mcPrograms.find((program: any) => program.programCode === report.programCode);
                    const matchingDescription = mcReports.find((desc: any) => desc.reportName === report.reportName);

                    if (matchingProgram && matchingDescription) {
                        return {
                            ...report,
                            programCode: matchingProgram.programName,
                            reportDescription: matchingDescription.reportDescription
                        };
                    }
                    return report;
                });

                const sortedRecords = matchingRecords.sort((a: any, b: any) => {
                    if (a.reportName < b.reportName) return -1;
                    if (a.reportName > b.reportName) return 1;
                    return 0;  // If both are the same, keep the original order
                });

                setFilterData(sortedRecords);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    const getAllReportReviewer = async () => {
        try {
            const response = await ReportInventoryService.getAllReportReviewer();
            if (response) {
                const { data } = response;
                setReviewersGroupsList(data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    const fetchGetMasterTableLookupValueData = async () => {
        try {
            const response = await getMasterTableLookupValueData();
            if (response) {
                const { data } = response;

                const filterAndMapData = (group: string, table: string, column: string) => {
                    return data
                        .filter((dt: any) => dt.lkpcd_group === group && dt.table_name === table && dt.column_name === column)
                        .map((code: any) => ({
                            lkpdmn_lkpcd: `${code.lkpdmn_lkpcd}`,
                            lkdmn_lkpcd_desc: code.lkdmn_lkpcd_desc,
                            lkpcd_group: code.lkpcd_group,
                        }));
                };

                const mcReportType = filterAndMapData('AL', 'MC_PROGRAM_REPORT', 'REPORTING_TYPE_LKPCD');
                const mcContentType = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'REPORTING_CONTENT_TYPE_LKPCD');
                const mcReportingGroup = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'REPORTING_GROUP_QLFR_LKPCD');
                const mcFrequencyLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'DUE_DATE_FREQUENCY_LKPCD');
                const mcSubChannelLKPCD = filterAndMapData('AD', 'MC_PROGRAM_REPORT', 'REPORT_SBMSN_MODE_LKPCD');
                const mcFileFormatLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'REPORT_FILE_TYPE_LKPCD');
                const mcBusinessStatusLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'BUSINESS_STATUS_LKPCD');
                const mcAfStatusLKPCD = filterAndMapData('NG', 'MC_PROGRAM_REPORT', 'AF_STATUS_LKPCD');
                const mcFileUploadType = filterAndMapData('MC', 'MC_REPORT_FILENAME_TEMPLATE', 'UPLOAD_TYPE_LKPCD');
                const mcChecklistAfStatusLkpcde = filterAndMapData('RM', 'MC_CHECKLIST', 'CHECKLIST_TYPE_LKPCD');
                const mcMcoActionLkpcde = filterAndMapData('MC', 'MC_REPORT_REVIEWERS_GROUP', 'ACTION_LKPCD');
                const mcReviewerGroupLkpcde = filterAndMapData('NG', 'MC_REPORT_REVIEWERS_GROUP', 'AF_STATUS_LKPCD');


                setReportTypeList(mcReportType);
                setContentTypeList(mcContentType);
                setReportGroupList(mcReportingGroup);
                setFrequencyLKPCDList(mcFrequencyLKPCD);
                setReportFileFormat(mcFileFormatLKPCD);
                setBusinessStatusLKPCDList(mcBusinessStatusLKPCD);
                setAfStatusLKPCDList(mcAfStatusLKPCD);
                setUploadTypeLKPCDList(mcFileUploadType);
                setChecklistAfStatusLKPCDList(mcChecklistAfStatusLkpcde)
                setNcoActionLKPCDList(mcMcoActionLkpcde)
                setReviewerGroupLKPCDList(mcReviewerGroupLkpcde)
                setChannelLKPCDList(mcSubChannelLKPCD);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchGetMcUserGroupTableLookupValueData = async () => {
        try {
            const response = await getUserGroupTableLookupData();
            if (response) {
                const { data } = response;

                const filterAndMapData = () => {
                    return data
                        // .filter((dt: any) => dt.userGroupCode === group)
                        .map((code: any) => ({
                            userGroupCode: `${code.userGroupCode}`,
                            userGroupName: code.userGroupName,
                            userGroupUsage: code.userGroupUsage,
                            oprtnlFlag: code.oprtnlFlag,
                        }));
                };

                setMcUserGroupsList(filterAndMapData);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchGetMcOrganizationUnitsTableLookupValueData = async () => {
        try {
            const response = await getOrganizationUnitsTableLookupData();
            if (response) {
                const { data } = response;

                const filterAndMapData = () => {
                    return data
                        .filter((dt: any) => dt.organizationUnitCtgryLkpcd === 'NMR')
                        .map((code: any) => ({
                            organizationUnitCode: `${code.organizationUnitCode}`,
                            organizationUnitName: code.organizationUnitName,
                            organizationUnitCtgryLkpcd: code.organizationUnitCtgryLkpcd,
                            oprtnlFlag: code.oprtnlFlag,
                            parentOrgUnitCode: code.parentOrgUnitCode
                        }));
                };

                setMcOrgUnitsGroupsList(filterAndMapData);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getMatchingDescription = (list: any[], data: any) => {
        const matchedItem = list.find((code: any) => code.lkpdmn_lkpcd === data);
        return matchedItem?.lkdmn_lkpcd_desc || data;
    };

    const matchingFrequencyLkpcd = (data: any) => getMatchingDescription(frequencyLKPCDList, data);
    const matchingReportingTypeLkpcd = (data: any) => getMatchingDescription(reportTypeList, data);
    const matchingFormatLkpcd = (data: any) => getMatchingDescription(reportFileFormat, data);
    const matchingChannelLkpcd = (data: any) => getMatchingDescription(channelLKPCDList, data);
    const matchingBusinessStatusLkpcd = (data: any) => getMatchingDescription(businessStatusLKPCDList, data);
    const matchingReportingContentTypeLkpcd = (data: any) => getMatchingDescription(contentTypeList, data);
    const matchingProgramName = (data: any) => {
        const matchedItem = mcProgramData.find((code: any) => code.programCode === data);
        return matchedItem?.programName || data;
    };

    const formatDateAndTime = (value: any) => {
        // if (value) {
        //     const date = moment.utc(value);
        //     return date.isValid() ? date.format('M/D/YY h:mm A') : '';
        // }
        // return '';
        return formatDateForDisplay(value, 'M/D/YY h:mm A');

    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                await Promise.all([fetchGetMasterTableLookupValueData(), fetchGetMcUserGroupTableLookupValueData(), fetchGetMcOrganizationUnitsTableLookupValueData(), getAllReportReviewer(), fetchAllProgramReportData()]);
                // await fetchGetMasterTableLookupValueData()
                // await fetchGetMcUserGroupTableLookupValueData()
                // await fetchGetMcOrganizationUnitsTableLookupValueData()
                // await getAllReportReviewer();
                // await fetchAllProgramReportData();
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if (userType === null && (!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history, userType]);

    // useEffect(() => {
    //     setFilteredReportResponseList(filterData);
    // }, [filterData]);

    const mapLookupValues = (item: any) => {
        return {
            ...item,
            reportName: item.reportName,
            stateReferenceNumber: item.stateReferenceNumber,
            reportingTypeLkpcd: matchingReportingTypeLkpcd(item.reportingTypeLkpcd),
            reportingPeriodQlfrLkpcd: matchingFrequencyLkpcd(item.reportingPeriodQlfrLkpcd),
            reportFileTypeLkpcd: matchingFormatLkpcd(item.reportFileTypeLkpcd),
            reportSbmsnModeLkpcd: matchingChannelLkpcd(item.reportSbmsnModeLkpcd),
            businessStatusLkpcd: matchingBusinessStatusLkpcd(item.businessStatusLkpcd),
            updatedDate: formatDateForDisplay(item.updatedDate, 'M/D/YY h:mm A'),
        };
    };

    // const mappedData = (data: ReferenceList[]) => {
    //     return data.map(mapLookupValues);
    // };

    // useEffect(() => {
    //     const mapData = mappedData(filterData);
    //     setFilteredReportResponseList(mapData);
    // }, [filterData]);

    const mappedData = useMemo(() => {
        return filterData.map(mapLookupValues);
    }, [filterData]);

    useEffect(() => {
        setFilteredReportResponseList(mappedData)
    }, [mappedData]);

    const validateSearchBtn = () => {
        if (!(_.isEmpty(selectedOption1) || _.isEmpty(selectedOption2) || _.isEmpty(filter1Text) || _.isEmpty(filter2Text))) {
            return false;
        }
        return true;
    };

    const CustomCellRenderer = ({ value }: { value: string }) => (
        <div style={{ width: 'max-content', whiteSpace: 'nowrap' }}>{value}</div>
    );
    const referenceListHeaders: any[] = [
        {
            name: 'reportName',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Report Name
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            cellRenderer: CustomCellRenderer,
        },
        {
            name: 'reportingTypeLkpcd',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Report Type
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            cellRenderer: CustomCellRenderer,
            render: ({ data }: { data: ReferenceList }) => {
                return matchingReportingTypeLkpcd(data.reportingTypeLkpcd);
            },
        },
        {
            name: 'stateReferenceNumber',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Reference
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            cellRenderer: CustomCellRenderer,
        },
        {
            name: 'reportingPeriodQlfrLkpcd',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Frequency
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            cellRenderer: CustomCellRenderer,
            render: ({ data }: { data: ReferenceList }) => {
                return matchingFrequencyLkpcd(data.reportingPeriodQlfrLkpcd);
            },
        },
        {
            name: 'reportFileTypeLkpcd',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Format
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 100,
            cellRenderer: CustomCellRenderer,
            render: ({ data }: { data: ReferenceList }) => {
                return matchingFormatLkpcd(data.reportFileTypeLkpcd);
            },
        },
        {
            name: 'reportSbmsnModeLkpcd',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Submission Channel
                    </div>
                ),
            resizable: true,
            defaultFlex: 1,
            minWidth: 150,
            cellRenderer: CustomCellRenderer,
            render: ({ data }: { data: ReferenceList }) => {
                return matchingChannelLkpcd(data.reportSbmsnModeLkpcd);
            },
        },
        {
            name: 'businessStatusLkpcd',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Business Status
                    </div>
                ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 150,
            cellRenderer: CustomCellRenderer,
            render: ({ data }: { data: ReferenceList }) => {
                return matchingBusinessStatusLkpcd(data.businessStatusLkpcd);
            },
        },
        {
            name: 'updatedDate',
            header:
                (
                    <div className='grid-header-custom-style'>
                        Updated Date
                    </div>
                ),
            resizable: true,
            defaultFlex: 1,
            minWidth: 150,
            cellRenderer: CustomCellRenderer,
            render: ({ data }: { data: ReferenceList }) => {
                return formatDateAndTime(data.updatedDate);
            },
        }

    ]

    function handleFilterChange1(event: any) {
        const selectedValue = event.target.value;
        setSelectedOption1(selectedValue);
        setFilter1Text('');
        setIsDisabled(validateSearchBtn());
    }

    function handleFilterChange2(event: any) {
        const selectedValue = event.target.value;
        setSelectedOption2(selectedValue);
        setFilter2Text('');
        setIsDisabled(validateSearchBtn());
    }

    function handleFilterText1(event: any) {
        const enteredValue = event.target.value;
        setFilter1Text(enteredValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleFilterText2(event: any) {
        const enteredValue = event.target.value;
        setFilter2Text(enteredValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleClear() {
        sessionStorage.removeItem('savedFilters')
        setFilter1Text('');
        setFilter2Text('');
        setSelectedOption1(null);
        setSelectedOption2(null);
        setFilteredReportResponseList(filterData);
    }

    useEffect(() => {
        // Check if saved filters exist in sessionStorage
        const savedFilters = sessionStorage.getItem('savedFilters');
        if (savedFilters) {
            const parsedFilters = JSON.parse(savedFilters);
            setSelectedOption1(parsedFilters.selectedOption1);
            setSelectedOption2(parsedFilters.selectedOption2);
            setFilter1Text(parsedFilters.filter1Text);
            setFilter2Text(parsedFilters.filter2Text);
        }
    }, []);

    const [viewableText, setViewableText] = useState<any[]>([
        { value: 'Y', description: 'Yes' },
        { value: 'N', description: 'No' },
    ]);
    const getMatchingYesOrNoValues = (data: string): string => {
        const matchedItem = viewableText.find((code) => code.value.toLowerCase() === data.toLowerCase());
        return matchedItem?.description || '';
    };

    const filteredSerachDropdownChoices1 = isNonStateUser
        ? serachDropdownChoices1.filter(option => option.value === 'programName' ||
            option.value === 'certificationReqdFlag' || option.value === 'startDate' || option.value === 'endDate') : serachDropdownChoices1;

    const filteredSerachDropdownChoices2 = isNonStateUser
        ? serachDropdownChoices2.filter(option => option.value === 'programName' ||
            option.value === 'certificationReqdFlag' || option.value === 'startDate' || option.value === 'endDate') : serachDropdownChoices2

    function handleSearch() {
        const filtersToSave = {
            selectedOption1: selectedOption1 || '',
            selectedOption2: selectedOption2 || '',
            filter1Text: filter1Text || '',
            filter2Text: filter2Text || ''
        };

        // Only save if the filters have changed
        const currentFilters = JSON.parse(sessionStorage.getItem('savedFilters') || '{}');
        if (JSON.stringify(currentFilters) !== JSON.stringify(filtersToSave)) {
            sessionStorage.setItem('savedFilters', JSON.stringify(filtersToSave));
        }
        let filter1Value: string | number | Date | null = filter1Text;
        let filter2Value: string | number | Date | null = filter2Text;
        let programReportSids: string[] = [];

        const parseDate = (dateString: string) => {
            const formats = ['MM/DD/YYYY', 'YYYY-MM-DD', 'DD/MM/YYYY', 'MM-DD-YYYY', 'DD-MM-YYYY'];
            const date = moment(dateString, formats, true);
            return date.isValid() ? date.format('YYYY-MM-DD') : null;
        };

        // Determine the filter1Value based on selectedOption1
        if (selectedOption1 === 'programName') {
            filter1Value = filter1Text;
        } else if (selectedOption1 === 'reportingContentTypeLkpcd') {
            const matchingValue = contentTypeList.find((code: any) => code.lkdmn_lkpcd_desc.toLowerCase().includes((filter1Text || '').toLowerCase()));
            filter1Value = matchingValue ? matchingValue.lkpdmn_lkpcd : filter1Text;
        } else if (selectedOption1 === 'autoValidationFlag') {
            const matchingValue = viewableText.find((code: any) => code.description.toLowerCase().includes((filter1Text || '').toLowerCase()));
            filter1Value = matchingValue ? matchingValue.value : filter1Text;
        } else if (selectedOption1 === 'certificationReqdFlag') {
            const matchingValue = viewableText.find((code: any) => code.description.toLowerCase().includes((filter1Text || '').toLowerCase()));
            filter1Value = matchingValue ? matchingValue.value : filter1Text;
        } else if (selectedOption1 === 'startDate') {
            filter1Value = filter1Text ? parseDate(filter1Text) : '';
        } else if (selectedOption1 === 'endDate') {
            filter1Value = filter1Text ? parseDate(filter1Text) : '';
        } else if (selectedOption1 === 'reviewersGroup') {
            const matchingValue = mcOrgUnitsList.find((code: any) => code.organizationUnitName.toLowerCase().includes((filter1Text || '').toLowerCase()));
            const matchingReviewerGroup = reviewersGroupsList.filter((code: any) => code.organizationUnitCode === matchingValue.organizationUnitCode);
            programReportSids = matchingReviewerGroup.map((group: any) => group.programReportSid);
        }

        // Determine the filter2Value based on selectedOption2
        if (selectedOption2 === 'programName') {
            filter2Value = filter2Text;
        } else if (selectedOption2 === 'reportingContentTypeLkpcd') {
            const matchingValue = contentTypeList.find((code: any) => code.lkdmn_lkpcd_desc.toLowerCase().includes((filter2Text || '').toLowerCase()));
            filter2Value = matchingValue ? matchingValue.lkpdmn_lkpcd : filter2Text;
        } else if (selectedOption2 === 'autoValidationFlag') {
            const matchingValue = viewableText.find((code: any) => code.description.toLowerCase().includes((filter2Text || '').toLowerCase()));
            filter2Value = matchingValue ? matchingValue.value : filter2Text;
        } else if (selectedOption2 === 'certificationReqdFlag') {
            const matchingValue = viewableText.find((code: any) => code.description.toLowerCase().includes((filter2Text || '').toLowerCase()));
            filter2Value = matchingValue ? matchingValue.value : filter2Text;
        } else if (selectedOption2 === 'startDate') {
            filter2Value = filter2Text ? parseDate(filter2Text) : '';
        } else if (selectedOption2 === 'endDate') {
            filter2Value = filter2Text ? parseDate(filter2Text) : '';
        } else if (selectedOption2 === 'reviewersGroup') {
            const matchingValue = mcOrgUnitsList.find((code: any) => code.organizationUnitName.toLowerCase().includes((filter2Text || '').toLowerCase()));
            const matchingReviewerGroup = reviewersGroupsList.filter((code: any) => code.organizationUnitCode === matchingValue.organizationUnitCode);
            programReportSids = matchingReviewerGroup.map((group: any) => group.programReportSid);
        }

        const filteredList = filterData.filter(item => {
            const matchesOption1 = selectedOption1
                ? selectedOption1 === 'reviewersGroup'
                    ? programReportSids.includes(item.programReportSid || '')
                    : selectedOption1 === 'startDate' || selectedOption1 === 'endDate'
                        ? parseDate(item[selectedOption1 as keyof ReferenceList] as string) === filter1Value
                        : typeof item[selectedOption1 as keyof ReferenceList] === 'string'
                            ? (item[selectedOption1 as keyof ReferenceList] as string).toLowerCase().includes((filter1Value as string).toLowerCase())
                            : item[selectedOption1 as keyof ReferenceList] === filter1Value
                : true;

            const matchesOption2 = selectedOption2
                ? selectedOption2 === 'reviewersGroup'
                    ? programReportSids.includes(item.programReportSid || '')
                    : selectedOption2 === 'startDate' || selectedOption2 === 'endDate'
                        ? parseDate(item[selectedOption2 as keyof ReferenceList] as string) === filter2Value
                        : typeof item[selectedOption2 as keyof ReferenceList] === 'string'
                            ? (item[selectedOption2 as keyof ReferenceList] as string).toLowerCase().includes((filter2Value as string).toLowerCase())
                            : item[selectedOption2 as keyof ReferenceList] === filter2Value
                : true;

            return matchesOption1 && matchesOption2;
        });

        setFilteredReportResponseList(filteredList);
    }

    const headers = [
        'Report Name',
        'Report Type',
        'Reference Number',
        'Frequency',
        'Format',
        'Submission Channel',
        "Business Status",
        'Updated Date',
        'Program',
        'Report Content Type',
        'Initial Verification Flag',
        'Need Certification Flag',
        'Report Begin Date',
        'Report End Date',
        // 'Reviewers Group'
    ];

    const excludedHeaders = [
        'Initial Verification Flag',
        'Report Content Type'
    ];

    const filteredHeaders = isNonStateUser
        ? headers.filter(header => !excludedHeaders.includes(header))
        : headers;

    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [selectedHeaders, setSelectedHeaders] = useState<string[]>(filteredHeaders);
    const toggleModal = () => {
        setSelectedHeaders(filteredHeaders);
        setIsExportModalOpen(!isExportModalOpen);
    };

    const handleHeaderChange = (header: string) => {
        setSelectedHeaders((prev) =>
            prev.includes(header) ? prev.filter((h) => h !== header) : [...prev, header]
        );
    };

    const handleSelectAllChange = () => {
        setSelectedHeaders(selectedHeaders.length === filteredHeaders.length ? [] : filteredHeaders);
    };

    const handleExportToExcel = () => {
        const dataToExport = filterDataList.map((item) => {
            const row: { [key: string]: any } = {};
            selectedHeaders.forEach((header) => {
                row[header] = {
                    "Report Name": item.reportName,
                    "Report Type": matchingReportingTypeLkpcd(item.reportingTypeLkpcd),
                    "Reference Number": item.stateReferenceNumber,
                    "Frequency": matchingFrequencyLkpcd(item.reportingPeriodQlfrLkpcd),
                    "Format": matchingFormatLkpcd(item.reportFileTypeLkpcd),
                    "Submission Channel": matchingChannelLkpcd(item.reportSbmsnModeLkpcd),
                    "Business Status": matchingBusinessStatusLkpcd(item.businessStatusLkpcd),
                    'Updated Date': formatDateForDisplay(item.updatedDate, 'M/D/YY h:mm A'),
                    "Program": matchingProgramName(item.programCode),
                    "Report Content Type": matchingReportingContentTypeLkpcd(item.reportingContentTypeLkpcd),
                    "Initial Verification Flag": getMatchingYesOrNoValues(item.autoValidationFlag || ''),
                    "Need Certification Flag": getMatchingYesOrNoValues(item.certificationReqdFlag || ''),
                    "Report Begin Date": formatDateForDisplay(item.startDate, 'MM/DD/YYYY'),
                    "Report End Date": formatDateForDisplay(item.endDate, 'MM/DD/YYYY'),
                    // "Reviewers Group": item.reviewersGroup
                }[header];
            });
            return row;
        });
        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const headerRow = selectedHeaders.map((header) => header);
        XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: 'A1' });

        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Report Master Catalog');
        XLSX.writeFile(wb, 'report_master_catalog.xlsx');
        setIsExportModalOpen(false);
    }

    const handleRowClick = (data: any) => {
        history.push({
            pathname: '/report-catalog-details',
            state: { recordData: data }
        });
    }

    // Memoize mapped data to avoid redundant calculations
    const mappedDate1 = useMemo(() => filterData.map(mapLookupValues), [filterData]);

    // Debounce hook to delay filtering execution
    const useDebouncedValue = (value: any, delay = 300) => {
        const [debouncedValue, setDebouncedValue] = useState(value);
        useEffect(() => {
            const handler = setTimeout(() => setDebouncedValue(value), delay);
            return () => clearTimeout(handler);
        }, [value, delay]);
        return debouncedValue;
    };

    const [filterDataList, setFilterDataList] = useState<ReferenceList[]>([]);

    // Debounced filter value to optimize performance
    const debouncedFilterValue = useDebouncedValue(filterValue, 300);

    const handleFilterChangeGrid = useCallback((ev: any) => {
        savedFilters.ReferencePage = ev;
        setFilterDataList([]);
    }, []);

    // Normalize values for consistent filtering
    const normalizeValue = (value: any): string | Set<string> => {
        if (value === null || value === undefined) return "";
        if (typeof value === "string") return value.toLowerCase();
        if (typeof value === "number" || typeof value === "boolean") return value.toString();
        if (value instanceof Date) return value.toISOString().toLowerCase();
        if (Array.isArray(value)) return new Set(value.map((v) => normalizeValue(v) as string));
        return "";
    };

    // Memoize normalized filter values
    const normalizedFilterValue = useMemo(() => {
        return debouncedFilterValue?.map(({ name, value }: any) => ({
            name,
            value: normalizeValue(value),
        })) ?? [];
    }, [debouncedFilterValue]);

    // Optimized filtering function
    const applyFilters = useCallback(() => {
        if (!normalizedFilterValue.length) {
            setFilterDataList(mappedDate1);
            return;
        }

        const filteredData = mappedDate1.filter((item: any) => {
            return normalizedFilterValue.every(({ name, value }: any) => {
                const itemValue = normalizeValue(item[name]);

                if (itemValue instanceof Set) {
                    return Array.from(itemValue).some((val) => val.includes(value));
                } else {
                    return itemValue.includes(value);
                }
            });
        });

        // Update state only if data has changed
        setFilterDataList((prevData) =>
            JSON.stringify(prevData) === JSON.stringify(filteredData) ? prevData : filteredData
        );
    }, [mappedDate1, normalizedFilterValue]);

    // Apply filters when data or filters change
    useEffect(() => {
        applyFilters();
    }, [applyFilters]);

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="Report Master Catalog" />
                </div>
            </div>

            <div className="form-layout">
                <div className="filters-row grid-cols-1 gap-4 md:grid-cols-2 lg:flex lg:justify-between lg:flex-wrap lg:items-center">
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <Dropdown
                            choices={filteredSerachDropdownChoices1}
                            emptyChoiceLabel="Select Filter Criteria 1"
                            name="filterCriteria1"
                            label=""
                            data-testid="payment_type_testid"
                            value={selectedOption1 ? selectedOption1 : ''}
                            onChange={handleFilterChange1}
                        />
                    </div>
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <InputText
                            context="registration"
                            name="search"
                            placeholder="Filter 1"
                            value={filter1Text || ''}
                            onChange={handleFilterText1}
                            small={true}
                        />
                    </div>
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <Dropdown
                            choices={filteredSerachDropdownChoices2}
                            emptyChoiceLabel="Select Filter Criteria 2"
                            name="filterCriteria2"
                            label=""
                            data-testid="payment_type_testid"
                            value={selectedOption2 ? selectedOption2 : ''}
                            onChange={handleFilterChange2}
                            disabled={(!selectedOption1 && !filter1Text) || filter1Text === '' || filter1Text === null}

                        />
                    </div>
                    <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                        <InputText
                            context="registration"
                            name="search"
                            placeholder="Filter 2"
                            value={filter2Text || ''}
                            onChange={handleFilterText2}
                            small={true}
                            disabled={(!selectedOption1 && !filter1Text) || filter1Text === '' || filter1Text === null}
                        />
                    </div>
                    <div className="external-filters-action flex justify-end w-full lg:w-auto" style={{ marginBottom: '-20px' }}>
                        <Button
                            disabled={
                                !(
                                    (selectedOption1 && filter1Text) ||
                                    (selectedOption2 && filter2Text)
                                )
                            }
                            className="OkButton"
                            onClick={() => handleClear()}
                        >
                            Clear
                        </Button>
                        <Button
                            disabled={
                                !(
                                    (selectedOption1 && filter1Text) ||
                                    (selectedOption2 && filter2Text)
                                )
                            }
                            className="OkButton"
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                        <Button
                            className="OkButton"
                            onClick={toggleModal}
                            disabled={!filterData || filterData.length === 0 ||
                                checkAccess('report_master_detail_page', 'NOAC')
                            }
                        >
                            Export to Excel
                        </Button>
                    </div>
                </div>

                <Modal
                    isOpen={isExportModalOpen}
                    onRequestClose={toggleModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Export data"
                    style={{
                        content: {
                            width: '500px',
                            height: '500px',
                            margin: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        },
                    }}
                >
                    <h2 style={{ marginBottom: '20px' }}><b>Select Fields for Export</b></h2>
                    <div>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <input
                                type="checkbox"
                                id="selectAll"
                                checked={selectedHeaders.length === filteredHeaders.length}
                                onChange={handleSelectAllChange}
                                style={{ width: '15px', height: '15px', marginRight: '10px' }}
                            />
                            <label htmlFor="selectAll" style={{ verticalAlign: 'middle' }}>Select All</label>
                        </div>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                            {filteredHeaders.map((header) => (
                                <div key={header} style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="checkbox"
                                        id={header}
                                        checked={selectedHeaders.includes(header)}
                                        onChange={() => handleHeaderChange(header)}
                                        style={{ width: '15px', height: '15px', marginRight: '10px' }}
                                    />
                                    <label htmlFor={header} style={{ verticalAlign: 'middle' }}>{header}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                        <button
                            disabled={selectedHeaders.length === 0}
                            className='usa-button'
                            onClick={handleExportToExcel}
                        >
                            Download Excel
                        </button>
                        <button
                            className='usa-button'
                            onClick={toggleModal}
                        >
                            Close
                        </button>
                    </div>
                </Modal>
                {isLoading ? (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                        <p>Loading.....</p>
                    </div>
                ) :
                    <>
                        <ReactDataGrid
                            licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                            idProperty="key"
                            style={gridStyle}
                            pagination
                            columns={referenceListHeaders}
                            dataSource={filteredReportResponseList}
                            sortable={true}
                            onFilterValueChange={handleFilterChangeGrid}
                            defaultLimit={10}
                            defaultFilterValue={filterValue}
                            // enableColumnAutosize
                            rowHeight={50}
                            onRowClick={({ data }) => handleRowClick(data)}
                            className="pointer-cursor-row"
                            enableColumnAutosize={true}
                            handle={setGridRef}
                            pageSizes={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                        />
                    </>
                }
            </div>
            <Footer />
        </>
    );
}
export default Reference;