export enum SubmissionStatus {
    NOT_SCHEDULED = '05',
    SCHEDULED = '10',
    PAST_DUE = '15',
    EXTENSION_REQUESTED = '20',
    RESCHEDULED = '25',
    SUBMITTED = '30',
    RESUBMITTED = '35',
    REOPEN_REQUESTED = '40',
    CLOSED = '50'
}

export enum ReviewStatus {
    VIRUS_SCAN = '05',
    IN_INITIAL_VALIDATION = '10',
    INITIAL_VALIDATION_FAILED = '15',
    IN_REVIEW = '20',
    ADDITIONAL_INFO_REQUESTED = '30',
    ADDITIONAL_INFO_REQUESTED_VIRUS_SCAN = '32',
    ADDITIONAL_INFO_SUBMITTED = '31',
    ADDITIONAL_INFO_INREVIEW = '35',
    REJECTED = '40',
    ACCEPTED = '50',
}

export enum ChooseActionsType {
    EXTEND = 'ED',
    REOPEN = 'RO',
    SUBMIT = 'SB'
}

export enum ActivityLkpcd {
    ACTIVITY_110 = '110',
    ACTIVITY_120 = '120',
    ACTIVITY_125 = '125',
    ACTIVITY_140 = '140',
    ACTIVITY_150 = '150',
    ACTIVITY_153 = '153',
    ACTIVITY_160 = '160',
    ACTIVITY_180 = '180',
    ACTIVITY_190 = '190',
    ACTIVITY_210 = '210',
    ACTIVITY_220 = '220',
    ACTIVITY_230 = '230',
    ACTIVITY_240 = '240',
    ACTIVITY_243 = '243',
    ACTIVITY_244 = '244',
    ACTIVITY_246 = '246',
    ACTIVITY_250 = '250',
    ACTIVITY_260 = '260',
    ACTIVITY_270 = '270',
    ACTIVITY_273 = '273',
    ACTIVITY_274 = '274',
    ACTIVITY_276 = '276',
    ACTIVITY_280 = '280',
    ACTIVITY_285 = '285',
    ACTIVITY_290 = '290',
    ACTIVITY_300 = '300',
    ACTIVITY_301 = '301',
    ACTIVITY_303 = '303',
    ACTIVITY_304 = '304',
    ACTIVITY_305 = '305',
    ACTIVITY_306 = '306',
    ACTIVITY_308 = '308',
    ACTIVITY_310 = '310',
    ACTIVITY_313 = '313',
    ACTIVITY_314 = '314',
    ACTIVITY_316 = '316',
    ACTIVITY_320 = '320',
    ACTIVITY_325 = '325',
    ACTIVITY_330 = '330',
    ACTIVITY_340 = '340',
    ACTIVITY_341 = '341',
    ACTIVITY_343 = '343',
    ACTIVITY_344 = '344',
    ACTIVITY_345 = '345',
    ACTIVITY_346 = '346',
    ACTIVITY_348 = '348',
    ACTIVITY_350 = '350',
    ACTIVITY_154 = '154',
    ACTIVITY_155 = '155',
    ACTIVITY_156 = '156',
    ACTIVITY_165 = '165',
    ACTIVITY_166 = '166',
    ACTIVITY_170 = '170',
    ACTIVITY_175 = '175',
}

export enum AdhocChecklistId {
    CHECKLIST_ID_21 = '21',
    CHECKLIST_ID_22 = '22',
    CHECKLIST_ID_23 = '23'
}

export enum Organization_Unit_CtgryLkpcd {
    NMA = 'NMA',
    NMR = 'NMR',
    NMF = 'NMF',
    MCP = 'MCP',
    NSO = 'NSO',
    NSU = 'NSU'
}

export enum Reviewer_Type {
    IR = 'IR',
    FR = 'FR'
}

export enum CheckList_Type_Lkpcd {
    SFRL = 'SFRL',
    SIRL = 'SIRL',
    MSBL = 'MSBL',
    MAIL = 'MAIL',
    MORL = 'MORL',
    SIRA = 'SIRA',
    MSBA = 'MSBA',
    SFRA = 'SFRA',
}

export enum Final_Reviewer_File_Type_Filter {
    REQUEST_ADD_INFO = 'RI',
    OTHER = 'OT',
}

export enum Success_Messages {
    File_Deleted_Successfully = 'File deleted successfully',
    File_download_successfully = 'File download successfully',
    File_status_updated_successfully = 'File status updated successfully',
    Last_activity_deleted_successfully = 'Last activity deleted successfully',
    Save_successfully = 'Save successfully',
    Submit_successfully = 'Submit successfully',
    Review_record_saved_successfully = 'Review record saved successfully.',
    Review_record_submitted_successfully = 'Review record submitted successfully.',
    New_Report_data_has_been_successfully_uploaded = 'New Report data has been successfully uploaded.',
    All_reports_created_successfully = 'All reports created successfully.',
    Report_saved_successfully = 'Report saved successfully',
    Report_submitted_successfully = 'Report submitted successfully',
    Last_saved_activity_successfully_updated = 'Last saved activity successfully updated',
}

export enum Warning_Messages {
    Please_contact_MLTC_coordinator = 'In case you need to know more related to this activity, please contact MLTC coordinator.',
    One_Report_File_already_selected = 'One Report File already selected.',
    Must_have_the_Report_File = 'Must have the Report File',
    Associated_File_not_allowed = 'Associated File not allowed.',
    One_Certification_Statement_already_selected = 'One Certification Statement already selected.',
    Must_have_the_Certification_Statement = 'Must have the Certification Statement',
}

export enum Error_Messages {
    No_permissions_found_for_this_user = 'No permissions found for this user.',
    No_permissions_found_Please_contact_NEDHHS_administartor = 'No permissions found, \nPlease contact NEDHHS administartor.',
    An_error_occurred_while_fetching_permissions = 'An error occurred while fetching permissions.',
    Error_while_file_deleting = 'Error while file deleting',
    Error_while_updating_file_status = 'Error while updating file status',
    Error_while_deleting_last_saved_activity = 'Error while deleting last saved activity',
    Error_while_updating_last_activity = 'Error while updating last activity',
    Report_instance_statuses_not_set_up = 'It seems the report instance statuses are not set up correctly. Please contact the application administrator to resolve this issue.',
    Unexpected_MCO_action_allowed = 'There are unexpected actions that are allowed for MCO Action currently. Contact Application Administrator to resolve this issue.',
    File_name_patterns_failed = 'File name patterns failed',
    Last_activity_submit_status_not_Y = "Last activity's submit status is not 'Y'",
    File_size_exceeds_max_limit = 'File size exceeds maximum limit 10MB',
    Report_instance_already_modified = 'Another user has worked on this report instance already.',
    An_error_occurred = 'An error occurred.',
    Activity_statuses_not_set_up = 'It seems the activity statuses are not set up correctly. Please contact the application administrator to resolve this issue.',
    Filename_special_chars_not_accepted = 'The network does not accept some special characters in filenames. Please rename this file and upload again.',
    Error_saving_review = 'An error occurred while saving the review. Please try again later.',
    Error_saving_record = 'An error occurred while saving the record. Please try again later.',
    Error_submitting_review = 'An error occurred while submitting the review. Please try again later.',
    Error_submitting_record = 'An error occurred while submitting the record. Please try again later.',
    Not_authorized_reviewer_group = 'Not authorized for this reviewer group.',
    Error_checking_user_access = 'An error occurred while checking user access. Please try again later.',
    Failed_to_download_error_details = 'Failed to download error details file. Please try again later.',
    Failed_to_download_template = 'The system has failed to download this template. Please contact the MLTC for its copy.',
    Error_creating_report = 'Error creating report',
    Failed_to_save_report = 'Failed to save report',
    Failed_to_save_or_submit_actions = 'Failed to save or submit actions',
    Failed_to_login = 'Failed to login',
    Some_reports_failed_to_create = 'Some reports failed to create.',
}