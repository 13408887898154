import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [b2cUserGroups, setB2cUserGroups] = useState([]);
  const [userDetails, setUserDetails] = useState(null);

  return (
    <UserContext.Provider value={{ b2cUserGroups, setB2cUserGroups, userDetails, setUserDetails }}>
      {children}
    </UserContext.Provider>
  );
};

const userDetailsControl = () => {
    return useContext(UserContext);
};

export { UserProvider, userDetailsControl };