import React, { useState, useEffect, useMemo, useCallback } from 'react'
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import BackButton from '../../component/Common/BackBtn'
import { useMsal } from '@azure/msal-react'
import { useHistory } from 'react-router-dom'
import PageSubTitle from '../../component/Common/PageSubTitle'
import ReactDataGrid from '@inovua/reactdatagrid-enterprise'
import { useReportInventoryService } from '../../services/ReportInventoryServices';
import { set } from 'lodash';
import _, { concat } from 'lodash'
import Button from '../../component/Common/Button'
import Dropdown from "../../component/Common/Dropdown";
import InputText from '../../component/Common/InputText'
import Modal from 'react-modal';
import * as XLSX from 'xlsx'
import './user-management.css'
import moment from 'moment'
import { useLooksUpData } from '../../utils/getLookupTableData';
import { useAccessControl } from '../../../src/utils/AccessControlContext';
import Cookies from 'js-cookie';
import { formatDateForDisplay, setDateInCST } from '../../utils/dateUtils';
import { savedFilters } from '../../App'

const UserManagement = () => {
    const { instance, accounts } = useMsal()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const gridStyle = { minHeight: 550, width: '100%' };
    const ReportInventoryService = useReportInventoryService();
    let [mcUsersList, setMcUsersList] = useState<UserList[]>([]);
    const [selectedOption1, setSelectedOption1] = useState(
        (savedFilters?.UserManagemnetPage as any)?.selectedOption1 ||
        (null as any),
    );
    const [selectedOption2, setSelectedOption2] = useState(
        (savedFilters?.UserManagemnetPage as any)?.selectedOption1 ||
        (null as any),
    );
    const [filter1Text, setFilter1Text] = useState<string | null>(null);
    const [filter2Text, setFilter2Text] = useState<string | null>(null);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [gridRef, setGridRef] = useState(null as any)
    let [mcOrgUnitsList, setMcOrgUnitsGroupsList] = useState<any>([]);
    const [accountStatusLKPCDList, setAccountStatusLKPCDList] = useState<any>([])
    const { getMasterTableLookupValueData, getOrganizationUnitsTableLookupData, getUserGroupTableLookupData } = useLooksUpData();
    const { checkAccess } = useAccessControl();
    const [filteredReportResponseList, setFilteredReportResponseList] = useState<UserList[]>([]);
    let [accountTypeList, setAccountTypeList] = useState<any>([]);
    let [reviewersGroupsList, setReviewersGroupsList] = useState<any>([]);
    let [mcUserGroupsList, setMcUserGroupsList] = useState<any>([]);
    const [filterSearchOption, setFilterSearchOption] = useState(false);
    const loggedInUserDetails = JSON.parse(sessionStorage.getItem('loginUserDetails') || '{}');
    const isNonStateUser = loggedInUserDetails.organizationUnitCode !== "NEDHHS";
    let envPrefix = process.env.REACT_APP_ENV_PREFIX || '';

    interface UserList {
        userSid: string;
        orgUnitCd: string;
        userId: string;
        accountTypeLkpcd: string;
        accountStatusLkpcd: string;
        startDate: string;
        endDate: string;
        updatedDate: string;
    }

    const headers = [
        'Organization',
        'Last Name',
        'First Name',
        'User/Email ID',
        'Effective From Data',
        'Effective To Date',
        'Account Status',
        'Updated Date'
    ];
    const [selectedHeaders, setSelectedHeaders] = useState<string[]>(headers);

    const fetchGetMcOrganizationUnitsTableLookupValueData = async () => {
        try {
            const response = await getOrganizationUnitsTableLookupData();
            if (response) {
                const { data } = response;

                const filterAndMapData = () => {
                    return data
                        // .filter((dt: any) => dt.organizationUnitCtgryLkpcd === 'NMR')
                        .map((code: any) => ({
                            organizationUnitCode: `${code.organizationUnitCode}`,
                            organizationUnitName: code.organizationUnitName,
                            organizationUnitCtgryLkpcd: code.organizationUnitCtgryLkpcd,
                            oprtnlFlag: code.oprtnlFlag,
                            parentOrgUnitCode: code.parentOrgUnitCode
                        }));
                };

                setMcOrgUnitsGroupsList(filterAndMapData);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchGetMasterTableLookupValueData = async () => {
        try {
            const response = await getMasterTableLookupValueData();
            if (response) {
                const { data } = response;

                const filterAndMapData = (group: string, table: string, column: string) => {
                    return data
                        .filter((dt: any) => dt.lkpcd_group === group && dt.table_name === table && dt.column_name === column)
                        .map((code: any) => ({
                            lkpdmn_lkpcd: `${code.lkpdmn_lkpcd}`,
                            lkdmn_lkpcd_desc: code.lkdmn_lkpcd_desc,
                            lkpcd_group: code.lkpcd_group,
                        }));
                };

                const mcAccountStatusLKPCD = filterAndMapData('NG', 'MC_USER', 'ACCOUNT_STATUS_LKPCD');
                const mcAccountTypeLKPCD = filterAndMapData('NG', 'MC_USER', 'ACCOUNT_TYPE_LKPCD');

                setAccountStatusLKPCDList(mcAccountStatusLKPCD);
                setAccountTypeList(mcAccountTypeLKPCD)
            }
        } catch (e) {
            console.log(e);
        }
    };

    const fetchAllMcUsersData = async () => {
        try {
            const response = await ReportInventoryService.getAllMcUsers();
            if (response) {
                let usersList = response.data;

                if (sessionStorage.getItem('selectedProfile') !== `DHHS_AR_NEMCIS_STT${envPrefix}-SUP-ADMIN` && sessionStorage.getItem('selectedProfile') !== `DHHS_AR_NEMCIS_STT${envPrefix}-APP-ADMIN`) {
                    usersList = usersList.filter((item: any) => item.email.toLowerCase() === loggedInUserDetails?.userEmail?.toLowerCase());
                    setFilterSearchOption(true);
                }

                // Sort the usersList by orgUnitCd, lastName, then firstName
                usersList.sort((a: any, b: any) => {
                    if (a.orgUnitCd !== b.orgUnitCd) {
                        return a.orgUnitCd.localeCompare(b.orgUnitCd);
                    }
                    if (a.userLastName !== b.userLastName) {
                        return a.userLastName.localeCompare(b.userLastName);
                    }
                    return a.userFirstName.localeCompare(b.userFirstName);
                });

                setMcUsersList(usersList);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getMcUserGroupsByGroupCode = async (groupCode: any) => {
        try {
            const response = await ReportInventoryService.getMcUserGroupsByGroupCode(groupCode);
            if (response) {
                // setFilteredReportResponseList(response.data);
                return response.data;
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getReviewAssignmentListByUnitCode = async (orgUnitCode: any) => {
        try {
            const response = await ReportInventoryService.getReviewAssignmentListByUnitCode(orgUnitCode);
            if (response) {
                // setMcUsersList(response.data);
                return response.data;
            }
        } catch (e) {
            console.error(e);
        }
    };


    const fetchGetMcUserGroupTableLookupValueData = async () => {
        try {
            const response = await getUserGroupTableLookupData();
            if (response) {
                const { data } = response;

                const filterAndMapData = () => {
                    return data
                        // .filter((dt: any) => dt.userGroupCode === group)
                        .map((code: any) => ({
                            userGroupCode: `${code.userGroupCode}`,
                            userGroupName: code.userGroupName,
                            userGroupUsage: code.userGroupUsage,
                            oprtnlFlag: code.oprtnlFlag,
                        }));
                };

                setMcUserGroupsList(filterAndMapData);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const getAllReportReviewer = async () => {
        try {
            const response = await ReportInventoryService.getAllReportReviewer();
            if (response) {
                const { data } = response;
                setReviewersGroupsList(data);
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                await fetchAllMcUsersData();
                await fetchGetMasterTableLookupValueData();
                await fetchGetMcOrganizationUnitsTableLookupValueData();
                await getAllReportReviewer();
                await fetchGetMcUserGroupTableLookupValueData();
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const timeoutId = setTimeout(() => {
            if ((!accounts?.[0]?.localAccountId)) {
                history.push('/sign-in');
            }
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [accounts, history]);

    useEffect(() => {
        setFilteredReportResponseList(mcUsersList);
    }, [mcUsersList]);

    const getMatchingDescription = (list: any[], data: any) => {
        const matchedItem = list.find((code: any) => code.lkpdmn_lkpcd === data);
        return matchedItem?.lkdmn_lkpcd_desc || data;
    };

    const matchingAccountStatusLkpcd = (data: any) => getMatchingDescription(accountStatusLKPCDList, data);

    const serachDropdownChoices1 = [
        {
            label: 'Account Type',
            value: 'accountTypeLkpcd',
        },
        {
            label: 'Reviewer Group',
            value: 'reviwersGroup',
        },
        {
            label: 'User Group',
            value: 'userGroups',
        }
    ]

    const serachDropdownChoices2 = [
        {
            label: 'Account Type',
            value: 'accountTypeLkpcd',
        },
        {
            label: 'Reviewer Group',
            value: 'reviwersGroup',
        },
        {
            label: 'User Group',
            value: 'userGroups',
        }
    ]

    const validateSearchBtn = () => {
        if (!(_.isEmpty(selectedOption1) || _.isEmpty(selectedOption2) || _.isEmpty(filter1Text) || _.isEmpty(filter2Text))) {
            return false;
        }
        return true;
    };

    function handleFilterChange1(event: any) {
        const selectedValue = event.target.value;
        setSelectedOption1(selectedValue);
        setIsDisabled(validateSearchBtn());
        setFilter1Text('');
    }

    function handleFilterChange2(event: any) {
        const selectedValue = event.target.value;
        setSelectedOption2(selectedValue);
        setIsDisabled(validateSearchBtn());
        setFilter2Text('');
    }

    function handleFilterText1(event: any) {
        const enteredValue = event.target.value;
        setFilter1Text(enteredValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleFilterText2(event: any) {
        const enteredValue = event.target.value;
        setFilter2Text(enteredValue);
        setIsDisabled(validateSearchBtn());
    }

    function handleClear() {
        sessionStorage.removeItem('savedFilters')
        setFilter1Text('');
        setFilter2Text('');
        setSelectedOption1(null);
        setSelectedOption2(null);
        setFilteredReportResponseList(mcUsersList);
    }

    useEffect(() => {
        // Check if saved filters exist in sessionStorage
        const savedFilters = sessionStorage.getItem('savedFilters');
        if (savedFilters) {
            const parsedFilters = JSON.parse(savedFilters);
            setSelectedOption1(parsedFilters.selectedOption1);
            setSelectedOption2(parsedFilters.selectedOption2);
            setFilter1Text(parsedFilters.filter1Text);
            setFilter2Text(parsedFilters.filter2Text);
        }
    }, []);

    async function handleSearch() {
        const filtersToSave = {
            selectedOption1: selectedOption1 || '',
            selectedOption2: selectedOption2 || '',
            filter1Text: filter1Text || '',
            filter2Text: filter2Text || ''
        };

        // Only save if the filters have changed
        const currentFilters = JSON.parse(sessionStorage.getItem('savedFilters') || '{}');
        if (JSON.stringify(currentFilters) !== JSON.stringify(filtersToSave)) {
            sessionStorage.setItem('savedFilters', JSON.stringify(filtersToSave));
        }

        let filter1Value: string | number | Date | null = filter1Text;
        let filter2Value: string | number | Date | null = filter2Text;
        let programReportSids: string[] = [];
        let userSids: string[] = [];

        // Determine the filter1Value based on selectedOption1
        if (selectedOption1 === 'accountTypeLkpcd') {
            const matchingValue = accountTypeList.find((code: any) => code.lkdmn_lkpcd_desc.toLowerCase().includes((filter1Text || '').toLowerCase()));
            filter1Value = matchingValue ? matchingValue.lkpdmn_lkpcd : filter1Text;
        } else if (selectedOption1 === 'userGroups') {
            const matchingValue = mcUserGroupsList.find((code: any) => code.userGroupName.toLowerCase().includes((filter1Text || '').toLowerCase()));
            if (matchingValue) {
                const matchingReviewerGroup = await getMcUserGroupsByGroupCode(matchingValue.userGroupCode);
                if (matchingReviewerGroup) {
                    userSids = matchingReviewerGroup.map((group: any) => group.userSid);
                }
            }
        } else if (selectedOption1 === 'reviwersGroup') {
            const matchingValue = mcOrgUnitsList.find((code: any) => code.organizationUnitName.toLowerCase().includes((filter1Text || '').toLowerCase()));

            if (matchingValue) {
                const matchingReviewerGroup = await getReviewAssignmentListByUnitCode(matchingValue.organizationUnitCode);
                if (matchingReviewerGroup) {
                    userSids = matchingReviewerGroup.map((group: any) => group.userSid);
                }
            }
        }

        // Determine the filter2Value based on selectedOption2
        if (selectedOption2 === 'accountTypeLkpcd') {
            const matchingValue = accountTypeList.find((code: any) => code.lkdmn_lkpcd_desc.toLowerCase().includes((filter2Text || '').toLowerCase()));
            filter2Value = matchingValue ? matchingValue.lkpdmn_lkpcd : filter2Text;
        } else if (selectedOption2 === 'userGroups') {
            const matchingValue = mcUserGroupsList.find((code: any) => code.userGroupName.toLowerCase().includes((filter2Text || '').toLowerCase()));
            if (matchingValue) {
                const matchingReviewerGroup = await getMcUserGroupsByGroupCode(matchingValue.userGroupCode);
                if (matchingReviewerGroup) {
                    userSids = matchingReviewerGroup.map((group: any) => group.userSid);
                }
            }
        } else if (selectedOption2 === 'reviwersGroup') {
            const matchingValue = mcOrgUnitsList.find((code: any) => code.organizationUnitName.toLowerCase().includes((filter1Text || '').toLowerCase()));
            if (matchingValue) {
                const matchingReviewerGroup = await getReviewAssignmentListByUnitCode(matchingValue.organizationUnitCode);
                if (matchingReviewerGroup) {
                    userSids = matchingReviewerGroup.map((group: any) => group.userSid);
                }
            }
        }

        const filteredList = mcUsersList.filter((item: any) => {
            const matchesOption1 = selectedOption1
                ? selectedOption1 === 'reviwersGroup' || selectedOption1 === 'userGroups'
                    ? userSids.includes(item.userDetailsSid)
                    : typeof item[selectedOption1 as keyof UserList] === 'string'
                        ? (item[selectedOption1 as keyof UserList] as string).toLowerCase().includes((filter1Value as string).toLowerCase())
                        : item[selectedOption1 as keyof UserList] === filter1Value
                : true;

            const matchesOption2 = selectedOption2
                ? selectedOption2 === 'reviwersGroup' || selectedOption2 === 'userGroups'
                    ? userSids.includes(item.userDetailsSid)
                    : typeof item[selectedOption2 as keyof UserList] === 'string'
                        ? (item[selectedOption2 as keyof UserList] as string).toLowerCase().includes((filter2Value as string).toLowerCase())
                        : item[selectedOption2 as keyof UserList] === filter2Value
                : true;

            return matchesOption1 && matchesOption2;
        });


        setFilteredReportResponseList(filteredList);
    }

    const toggleModal = () => {
        setSelectedHeaders(headers);
        setIsExportModalOpen(!isExportModalOpen);
    };

    const handleHeaderChange = (header: string) => {
        setSelectedHeaders((prev) =>
            prev.includes(header) ? prev.filter((h) => h !== header) : [...prev, header]
        );
    };

    const handleSelectAllChange = () => {
        setSelectedHeaders(selectedHeaders.length === headers.length ? [] : headers);
    };

    const handleExportToExcel = () => {
        const dataToExport = filterDataList.map((item: any) => {
            const row: { [key: string]: any } = {};
            selectedHeaders.forEach((header) => {
                row[header] = {
                    'Organization': item.orgUnitCd,
                    'Last Name': item.userLastName,
                    'First Name': item.userFirstName,
                    'User/Email ID': item.userEmail,
                    'Effective From Data': formatDateForDisplay(item.startDate, 'MM/DD/YYYY'),
                    'Effective To Date': formatDateForDisplay(item.endDate, 'MM/DD/YYYY'),
                    'Account Status': matchingAccountStatusLkpcd(item.accountStatusLkpcd),
                    'Updated Date': formatDateForDisplay(item.updatedDate, 'M/D/YY h:mm A'),
                }[header]
            });
            return row;
        });

        // Create a worksheet
        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const headerRow = selectedHeaders.map((header) => header);
        XLSX.utils.sheet_add_aoa(ws, [headerRow], { origin: 'A1' });

        // Create a workbook
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'user inventory list');
        XLSX.writeFile(wb, 'user_inventory_list.xlsx');
        setIsExportModalOpen(false);
    };

    const getMatchingMcOrgUnit = (data: any) => {
        const matchedItem = mcOrgUnitsList.find((code: any) => code.organizationUnitCode === data);
        return matchedItem?.organizationUnitName || data;
    };

    const formatDateForInput = (value: any) => {
        if (value) {
            const date = moment.utc(value);
            return date.isValid() ? date.format('MM/DD/YYYY') : '';
        }
    };

    const formatDateAndTime = (value: any) => {
        if (value) {
            const date = moment.utc(value);
            return date.isValid() ? date.format('M/D/YY h:mm:ss:SSS A') : '';
        }
        return '';

    };

    const UserInventoryHeaders: any[] = [
        {
            name: 'orgUnitCd',
            header: (
                <div className='grid-header-custom-style'>
                    Organization
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
            // render: ({ value }: { value: any }) => (
            //     <div>{getMatchingMcOrgUnit(value)}</div>
            // ),
        },
        {
            name: 'userLastName',
            header: (
                <div className='grid-header-custom-style'>
                    Last Name
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
        },
        {
            name: 'userFirstName',
            header: (
                <div className='grid-header-custom-style'>
                    First Name
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
        },
        {
            name: 'userEmail',
            header: (
                <div className='grid-header-custom-style'>
                    User/Email ID
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
        },
        {
            name: 'startDate',
            header: (
                <div className='grid-header-custom-style'>
                    Effective From Date
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
            render: ({ data }: { data: UserList }) => {
                return formatDateForInput(data.startDate)
            },
        },
        {
            name: 'endDate',
            header: (
                <div className='grid-header-custom-style'>
                    Effective To Date
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
            render: ({ data }: { data: UserList }) => {
                return formatDateForInput(data.endDate)
            },
        },
        {
            name: 'accountStatusLkpcd',
            header: (
                <div className='grid-header-custom-style'>
                    Account Status
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
            render: ({ data }: { data: UserList }) => {
                return matchingAccountStatusLkpcd(data.accountStatusLkpcd)
            },
        },
        {
            name: 'updatedDate',
            header: (
                <div className='grid-header-custom-style'>
                    Updated Date
                </div>
            ),
            defaultFlex: 1,
            resizable: true,
            minWidth: 80,
            // maxWidth: 300,
            render: ({ data }: { data: UserList }) => {
                return formatDateForDisplay(data.updatedDate, 'M/D/YY h:mm A');
            },
        },
    ];

    const filterValue = savedFilters.UserManagemnetPage ? savedFilters.UserManagemnetPage : [
        { name: 'orgUnitCd', operator: 'contains', type: 'string', value: '' },
        { name: 'userLastName', operator: 'contains', type: 'string', value: '' },
        { name: 'userFirstName', operator: 'contains', type: 'string', value: '' },
        { name: 'userEmail', operator: 'contains', type: 'string', value: '' },
        { name: 'startDate', operator: 'contains', type: 'string', value: '' },
        { name: 'endDate', operator: 'contains', type: 'string', value: '' },
        { name: 'accountStatusLkpcd', operator: 'contains', type: 'string', value: '' },
        { name: 'updatedDate', operator: 'contains', type: 'string', value: '' },
    ]

    const [filterDataList, setFilterDataList] = useState<UserList[]>([]);

    // Debounce hook to delay filter execution
    const useDebouncedValue = (value: any, delay = 300) => {
        const [debouncedValue, setDebouncedValue] = useState(value);
        useEffect(() => {
            const handler = setTimeout(() => setDebouncedValue(value), delay);
            return () => clearTimeout(handler);
        }, [value, delay]);
        return debouncedValue;
    };

    // Debounced filter value to optimize performance
    const debouncedFilterValue = useDebouncedValue(filterValue, 300);

    const handleFilterChangeGrid = useCallback((ev: any) => {
        savedFilters.UserManagemnetPage = ev;
        setFilterDataList([]); // Reset filter data on filter change
    }, []);

    // Normalize values for consistent filtering
    const normalizeValue = (value: any): string | Set<string> => {
        if (value === null || value === undefined) return "";
        if (typeof value === "string") return value.toLowerCase();
        if (typeof value === "number" || typeof value === "boolean") return value.toString();
        if (value instanceof Date) return value.toISOString().toLowerCase();
        if (Array.isArray(value)) return new Set(value.map((v) => normalizeValue(v) as string));
        return "";
    };

    // Memoize normalized filter values
    const normalizedFilterValue = useMemo(() => {
        return debouncedFilterValue?.map(({ name, value }: any) => ({
            name,
            value: normalizeValue(value),
        })) ?? [];
    }, [debouncedFilterValue]);

    // Optimized filtering function
    const applyFilters = useCallback(() => {
        if (!normalizedFilterValue.length) {
            setFilterDataList(filteredReportResponseList);
            return;
        }

        const filteredData = filteredReportResponseList.filter((item: any) => {
            return normalizedFilterValue.every(({ name, value }: any) => {
                const itemValue = normalizeValue(item[name]);

                if (itemValue instanceof Set) {
                    return Array.from(itemValue).some((val) => val.includes(value));
                } else {
                    return itemValue.includes(value);
                }
            });
        });

        // Update state only if data has changed
        setFilterDataList((prevData) =>
            JSON.stringify(prevData) === JSON.stringify(filteredData) ? prevData : filteredData
        );
    }, [filteredReportResponseList, normalizedFilterValue]);

    // Apply filters when data or filters change
    useEffect(() => {
        applyFilters();
    }, [applyFilters]);

    const handleRowClick = (data: any) => {
        if (!checkAccess("user_inventory_page", "NOAC")) {
            history.push({
                pathname: "/user-management-details",
                state: { recordData: data },
            });
        }
    };

    // Mapping function to format lookup values
    const mapLookupValues = (item: any) => ({
        ...item,
        startDate: formatDateForDisplay(item.startDate, "MM/DD/YYYY"),
        endDate: formatDateForDisplay(item.endDate, "MM/DD/YYYY"),
        accountStatusLkpcd: matchingAccountStatusLkpcd(item.accountStatusLkpcd),
        updatedDate: formatDateForDisplay(item.updatedDate, "M/D/YY h:mm A"),
    });

    // Apply mapping when user list changes
    useEffect(() => {
        setFilteredReportResponseList(mcUsersList.map(mapLookupValues));
    }, [mcUsersList]);

    return (
        <>
            <Navbar />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ alignSelf: 'flex-start' }}>
                    <BackButton />
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <PageSubTitle text="User Inventory" />
                </div>
            </div>
            <div className="form-layout">
                {!isNonStateUser ? (
                    <div className="filters-row grid-cols-1 gap-4 md:grid-cols-2 lg:flex lg:justify-between lg:flex-wrap lg:items-center">
                        <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                            <Dropdown
                                choices={serachDropdownChoices1}
                                emptyChoiceLabel="Select Filter Criteria 1"
                                name="filterCriteria1"
                                label=""
                                data-testid="payment_type_testid"
                                value={selectedOption1 ? selectedOption1 : ''}
                                onChange={handleFilterChange1}
                                disabled={filterSearchOption}
                            />
                        </div>
                        <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                            <InputText
                                context="registration"
                                name="search"
                                placeholder="Filter 1"
                                value={filter1Text || ''}
                                onChange={handleFilterText1}
                                small={true}
                                disabled={filterSearchOption}
                            />
                        </div>
                        <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                            <Dropdown
                                choices={serachDropdownChoices2}
                                emptyChoiceLabel="Select Filter Criteria 2"
                                name="filterCriteria2"
                                label=""
                                data-testid="payment_type_testid"
                                value={selectedOption2 ? selectedOption2 : ''}
                                onChange={handleFilterChange2}
                                disabled={(!selectedOption1 && !filter1Text) || filter1Text === '' || filter1Text === null || filterSearchOption}
                            />
                        </div>
                        <div className="external-filters-elem w-full lg:w-auto lg:max-w-xs">
                            <InputText
                                context="registration"
                                name="search"
                                placeholder="Filter 2"
                                value={filter2Text || ''}
                                onChange={handleFilterText2}
                                small={true}
                                disabled={(!selectedOption1 && !filter1Text) || filter1Text === '' || filter1Text === null || filterSearchOption}

                            />
                        </div>
                        <div className="external-filters-action flex justify-end w-full lg:w-auto" style={{ marginBottom: '-20px' }}>
                            <Button
                                disabled={
                                    !(
                                        (selectedOption1 && filter1Text) ||
                                        (selectedOption2 && filter2Text)
                                    )
                                }
                                className="OkButton"
                                onClick={() => handleClear()}
                            >
                                Clear
                            </Button>
                            <Button
                                disabled={
                                    !(
                                        (selectedOption1 && filter1Text) ||
                                        (selectedOption2 && filter2Text)
                                    )
                                }
                                className="OkButton"
                                onClick={handleSearch}
                            >
                                Search
                            </Button>
                            <Button
                                className="OkButton"
                                onClick={toggleModal}
                                disabled={(!mcUsersList || mcUsersList.length === 0) || !checkAccess('user_inventory_page', 'FULL')}
                            >
                                Export to Excel
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="filters-row grid-cols-1 gap-4 md:grid-cols-2 lg:flex lg:justify-between lg:flex-wrap lg:items-center">
                        <div className="external-filters-action flex justify-end w-full lg:w-auto" style={{ marginBottom: '-20px' }}>
                            <Button
                                className="OkButton"
                                onClick={toggleModal}
                                disabled={(!mcUsersList || mcUsersList.length === 0) || checkAccess('user_inventory_page', 'FULL')}
                            >
                                Export to Excel
                            </Button>
                        </div>
                    </div>
                )}


                <Modal
                    isOpen={isExportModalOpen}
                    onRequestClose={toggleModal}
                    shouldCloseOnOverlayClick={false}
                    contentLabel="Export data"
                    style={{
                        content: {
                            width: '500px',
                            height: '500px',
                            margin: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        },
                    }}
                >
                    <h2 style={{ marginBottom: '20px' }}><b>Select Fields for Export</b></h2>
                    <div>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                            <input
                                type="checkbox"
                                id="selectAll"
                                checked={selectedHeaders.length === headers.length}
                                onChange={handleSelectAllChange}
                                style={{ width: '15px', height: '15px', marginRight: '10px' }}
                            />
                            <label htmlFor="selectAll" style={{ verticalAlign: 'middle' }}>Select All</label>
                        </div>
                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                            {headers.map((header) => (
                                <div key={header} style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        type="checkbox"
                                        id={header}
                                        checked={selectedHeaders.includes(header)}
                                        onChange={() => handleHeaderChange(header)}
                                        style={{ width: '15px', height: '15px', marginRight: '10px' }}
                                    />
                                    <label htmlFor={header} style={{ verticalAlign: 'middle' }}>{header}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="usa-form-group" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
                        <button
                            disabled={selectedHeaders.length === 0 ||
                                checkAccess('user_inventory_page', 'VWNA') || checkAccess('user_inventory_page', 'NOAC')
                            }
                            className='usa-button'
                            onClick={handleExportToExcel}
                        >
                            Download Excel
                        </button>
                        <button
                            className='usa-button'
                            onClick={toggleModal}
                        >
                            Close
                        </button>
                    </div>
                </Modal>
                {isLoading ? (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                        <p>Loading.....</p>
                    </div>
                ) :
                    <>
                        <ReactDataGrid
                            licenseKey="AppName=CoherenceApp,Company=Coherence,ExpiryDate=2025-04-15T00:00:00.000Z,LicenseDeveloperCount=1,LicenseType=single_app,Ref=CoherenceLicenseRef,Z=983320388-1513273079-1127323721-2081795733-199375339958733905"
                            idProperty="key"
                            style={gridStyle}
                            pagination
                            columns={UserInventoryHeaders}
                            dataSource={filteredReportResponseList}
                            sortable={true}
                            onFilterValueChange={handleFilterChangeGrid}
                            defaultLimit={10}
                            defaultFilterValue={filterValue}
                            rowHeight={50}
                            onRowClick={({ data }) => handleRowClick(data)}
                            className="pointer-cursor-row"
                            enableColumnAutosize={true}
                            handle={setGridRef}
                            pageSizes={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                        />
                    </>
                }
            </div>
            <Footer />
        </>

    );
}
export default UserManagement;