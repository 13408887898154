import React, { useEffect, useRef, useState, useContext } from "react";
import Navbar from "../../component/HeaderData";
import Footer from "../../component/FooterData";
import { FaArrowRight } from "react-icons/fa";
import { useMsal } from "@azure/msal-react";
import LockIcon from '../assets/images/lockIcon.png'
import Cookies from "js-cookie";
import { loginRequest } from "../../utils/authConfig";
import { useHistory } from "react-router-dom";
import { Link, Route } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ManageDevIcon from '../../img/manage_dev.png'
import arrow from '../../img/arrow.png'
import './signin-options.css'
import { getB2CRedirectSignInUrl } from "../../utils/url-constant";
import { useLocation } from 'react-router-dom'
import { useReportInventoryService } from '../../services/ReportInventoryServices';
import { userDetailsControl } from '../../UserContext';

// import {  LockIcon } from "../svgs/Index";
export default function SigninLandingPage() {
  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(true)
  const [b2cUserDetails, setB2cUserDetails] = useState({})
  const [b2cUserGroupsUpdate, setB2cUserGroupsUpdate] = useState([])
  const { setB2cUserGroups } = userDetailsControl();

  const ReportInventoryService = useReportInventoryService();

  const history = useHistory();
  const historyRef = useRef(history); // Creating a ref for history
  let envPrefix = process.env.REACT_APP_ENV_PREFIX || '';

  //get B2C code from url
  function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  let query = useQuery()
  let b2bLoggedinUserCode = query.get('code')

  const fetchB2ccodes = async (authCode: any) => {
    try {
      const response = await ReportInventoryService.getB2cAuthCode(authCode);
      if (response && response.data) {
        setB2cUserDetails(response.data.userDetails);
        setB2cUserGroups(response.data.b2cGroupNames);

        // Set the access token in session storage
        sessionStorage.setItem('msalToken', response.data.authToken);
        sessionStorage.setItem('signedInUserType', 'HealthPlanUser');
        sessionStorage.setItem('signedInUserId', response.data.userDetails.b2cEmail)
        sessionStorage.setItem('signedInB2CPlanId', response.data.isMcPlanUser)

        const b2cGroups = response.data.b2cGroupNames || [];
        const dropdownOptions = b2cGroups
          .filter((group: string) => group.includes(envPrefix))
          .map((group: string) => ({
            value: group,
            label: `MCO ${group.split(envPrefix + '-')[1]}`,
          }));

        if (dropdownOptions.length === 1) {
          const singleOption = dropdownOptions[0];
          sessionStorage.setItem('selectedProfile', singleOption.value);
          sessionStorage.setItem('selectedProfileLabel', singleOption.label);
        }

        const userDetails = {
          userId: response.data.userDetails.b2cMemberId,
          userFirstName: response.data.userDetails.b2cGivenName,
          userLastName: response.data.userDetails.b2cFamilyName,
          userEmail: response.data.userDetails.b2cEmail,
          organizationUnitCode: response.data.userDetails.b2cExtensionOrganizationName,
          userNameId: response.data.userDetails.b2cUserName,
        };

        sessionStorage.setItem('loginUserDetails', JSON.stringify(userDetails));

        // Redirect to the home page or any other desired route
        history.push('/');
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  useEffect(() => {
    if (b2bLoggedinUserCode) {
      fetchB2ccodes(b2bLoggedinUserCode)
    }
  }, [b2bLoggedinUserCode])

  useEffect(() => {
    const getTokenAndSetCookie = async () => {
      if (accounts && accounts.length > 0) {
        try {
          // Acquire token silently
          const silentResult = await instance.acquireTokenSilent({
            scopes: ["openid", "profile", "email"], // Add the required scopes
            account: accounts[0] // Use the first account
          });

          // Set the access token as a cookie
          const accessToken = silentResult.accessToken;
          // Cookies.set("token", accessToken);
          sessionStorage.setItem('msalToken', accessToken)

          // Redirect to the home page or any other desired route
          history.push('/');

          // Set loading state to false
          setIsLoading(false);
        } catch (error) {
          console.error("Token acquisition error:", error);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    getTokenAndSetCookie();
  }, [instance, accounts]);

  const userType = sessionStorage.getItem('signedInUserType');

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      console.log('userType:', userType);
      if (userType || accounts?.[0]?.localAccountId) {
        history.push('/admin-dashboard');
      } else {
        history.push('/sign-in');
      }
      setIsLoading(false);
    }, 1000);
  }, [accounts, history, userType]);

  const adminSignInRouteFn = async () => {
    await instance.loginRedirect(loginRequest)
      .then((e) => {
        // history.push('/admin-dashboard');
      })
      .catch((e) => {
      });
  };

  const clearSiteData = async () => {
    // Clear local storage
    localStorage.clear();
    console.log("Local storage cleared");

    // Clear session storage
    sessionStorage.clear();
    console.log("Session storage cleared");

    // Clear IndexedDB
    if (window.indexedDB) {
      const databases = await window.indexedDB.databases();
      console.log(`IndexedDB databases found:`, databases);
      for (const db of databases) {
        if (db.name) {
          window.indexedDB.deleteDatabase(db.name);
          console.log(`IndexedDB ${db.name} deleted`);
        }
      }
    }

    // Remove cookies for the current domain
    const removeCookie = (name: string, domain: string) => {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
      console.log(`Cookie ${name} removed for domain ${domain}`);
    };

    // List of cookies to remove
    const cookiesToRemove = document.cookie.split(";").map(cookie => cookie.split("=")[0].trim());

    // Remove cookies for the current domain
    cookiesToRemove.forEach(cookie => removeCookie(cookie, window.location.hostname));

    // Attempt to remove cookies for the B2C domain
    const b2cDomain = new URL(getB2CRedirectSignInUrl()).hostname;
    cookiesToRemove.forEach(cookie => removeCookie(cookie, b2cDomain));

    // Log the cookies to verify they are removed
    console.log("Cookies after removal:", document.cookie);
  };

  const b2cSignInRouteFn = async () => {
    await clearSiteData();

    // Redirect to B2C sign-in URL
    const signInUrl = getB2CRedirectSignInUrl();
    if (signInUrl) {
      console.log("Redirecting to B2C sign-in URL:", signInUrl);
      window.location.href = signInUrl;
    } else {
      console.error("Sign-in URL is undefined");
    }
  };

  return (
    <div className="flex flex-col overflow-x-hidden h-screen ">
      {isLoading ? (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Loading.....</p>
        </div>
      ) :
        <React.Fragment>
          <Navbar />
          <div className="flex-grow">
            <div className="home_page_css">
              <div style={{ width: '100%' }} className={'row'}>
                <div className="col-12">
                  <h2 className="landing-heading-title">
                    Nebraska Managed Care Insight System
                  </h2>
                  <p className="font-base text-[16px] font-[#0F)F10] my-0">Please sign in to access the application.</p>
                </div>
              </div>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  '& > :not(style)': {
                    m: 1,
                    width: { xs: '100%', sm: 260 }, // 100% width on extra small screens, 260px on small screens and up
                    height: { xs: 'auto', sm: 175 }, // auto height on extra small screens, 175px on small screens and up
                    maxWidth: 260, // max width
                    maxHeight: 175, // max height
                  },
                  my: 1,
                  m: 0,
                }}
              >
                {/* {Admin sign in} */}
                <Route>
                  <Link
                    style={{
                      textDecoration: 'none',
                      marginTop: 20,
                      marginBottom: 50,
                    }}
                    to={'/'}
                  >
                    <Paper
                      sx={{ border: 7, borderColor: '#bac6de', borderRadius: '26px' }}
                      elevation={3}
                      onClick={adminSignInRouteFn}
                    >
                      <Typography component="div">
                        <Box sx={{ fontWeight: 'bold', m: 1 }}>
                          State User Sign In
                        </Box>
                      </Typography>
                      <Box>
                        <Box
                          sx={{
                            position: 'relative',
                            left: '0%',
                          }}
                        >
                          <Avatar
                            alt="Mange Developers Icon"
                            sx={{ width: 60, height: 60 }}
                            src={ManageDevIcon}
                          />
                          <Box
                            sx={{
                              inlineSize: 130,
                              fontSize: 10,
                              letterSpacing: 0,
                              position: 'relative',
                              top: -60,
                              left: '35%',
                              height: '9vh',
                            }}
                          >
                            Click here to access NEMCIS
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            maxWidth: '50px',
                            position: 'relative',
                            left: '75%',
                            top: -25,
                          }}
                        >
                          <Avatar
                            alt="Arrow"
                            sx={{ width: 30, height: 25 }}
                            src={arrow}
                          />
                        </Box>
                      </Box>
                    </Paper>
                  </Link>
                </Route>

                {/* {User sign in} */}
                {/* <Route>
                  <Link
                    style={{
                      textDecoration: 'none',
                      marginTop: 20,
                      marginBottom: 50,
                    }}
                    to={'/user-sign-in'}
                  >
                    <Paper
                      sx={{ border: 7, borderColor: '#bac6de', borderRadius: '26px' }}
                      elevation={3}
                    >
                      <Typography component="div">
                        <Box sx={{ fontWeight: 'bold', m: 1 }}>
                          Health Plan User Sign In
                        </Box>
                      </Typography>
                      <Box>
                        <Box
                          sx={{
                            position: 'relative',
                            left: '0%',
                          }}
                        >
                          <Avatar
                            alt="Mange Developers Icon"
                            sx={{ width: 60, height: 60 }}
                            src={ManageDevIcon}
                          />
                          <Box
                            sx={{
                              inlineSize: 130,
                              fontSize: 10,
                              letterSpacing: 0,
                              position: 'relative',
                              top: -60,
                              left: '35%',
                              height: '9vh',
                            }}
                          >
                            Click here to access NEMCIS
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            maxWidth: '50px',
                            position: 'relative',
                            left: '75%',
                            top: -25,
                          }}
                        >
                          <Avatar
                            alt="Arrow"
                            sx={{ width: 30, height: 25 }}
                            src={arrow}
                          />
                        </Box>
                      </Box>
                    </Paper>
                  </Link>
                </Route> */}

                {/* {B2C User Sign In} */}
                <Route>
                  <Link
                    style={{
                      textDecoration: 'none',
                      marginTop: 20,
                      marginBottom: 50,
                    }}
                    to={'/'}
                  >
                    <Paper
                      sx={{ border: 7, borderColor: '#bac6de', borderRadius: '26px' }}
                      elevation={3}
                      onClick={b2cSignInRouteFn}
                    >
                      <Typography component="div">
                        <Box sx={{ fontWeight: 'bold', m: 1 }}>
                          Health Plan User Sign In
                        </Box>
                      </Typography>
                      <Box>
                        <Box
                          sx={{
                            position: 'relative',
                            left: '0%',
                          }}
                        >
                          <Avatar
                            alt="Mange Developers Icon"
                            sx={{ width: 60, height: 60 }}
                            src={ManageDevIcon}
                          />
                          <Box
                            sx={{
                              inlineSize: 130,
                              fontSize: 10,
                              letterSpacing: 0,
                              position: 'relative',
                              top: -60,
                              left: '35%',
                              height: '9vh',
                            }}
                          >
                            Click here to access NEMCIS
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            maxWidth: '50px',
                            position: 'relative',
                            left: '75%',
                            top: -25,
                          }}
                        >
                          <Avatar
                            alt="Arrow"
                            sx={{ width: 30, height: 25 }}
                            src={arrow}
                          />
                        </Box>
                      </Box>
                    </Paper>
                  </Link>
                </Route>

                {/* {User sign up} */}
                {/* <Route>
                  <Link
                    style={{
                      textDecoration: 'none',
                      marginTop: 20,
                      marginBottom: 50,
                    }}
                    // to={'/user-sign-up'}
                    to={''}
                  >
                    <Paper
                      sx={{ border: 7, borderColor: '#bac6de', borderRadius: '26px' }}
                      elevation={3}
                    >
                      <Typography component="div">
                        <Box sx={{ fontWeight: 'bold', m: 1 }}>
                          New User Sign up
                        </Box>
                      </Typography>
                      <Box>
                        <Box
                          sx={{
                            position: 'relative',
                            left: '0%',
                          }}
                        >
                          <Avatar
                            alt="Mange Developers Icon"
                            sx={{ width: 60, height: 60 }}
                            src={ManageDevIcon}
                          />
                          <Box
                            sx={{
                              inlineSize: 130,
                              fontSize: 10,
                              letterSpacing: 0,
                              position: 'relative',
                              top: -60,
                              left: '35%',
                              height: '9vh',
                            }}
                          >
                            Click here to new user sign up
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            maxWidth: '50px',
                            position: 'relative',
                            left: '75%',
                            top: -25,
                          }}
                        >
                          <Avatar
                            alt="Arrow"
                            sx={{ width: 30, height: 25 }}
                            src={arrow}
                          />
                        </Box>
                      </Box>
                    </Paper>
                  </Link>
                </Route> */}
              </Box>
            </div>
          </div>
          <Footer />
        </React.Fragment>
      }
    </div >
  );
};